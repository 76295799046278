<template>
  <section class="header">
    <div class="top" v-if="!isShareLogin">
      <div class="top-content flex-between">
        <div v-if="!token" class="top-left">
          <div class="user-type">
            <span class="ico ico-member" />
            <span>个人用户</span>
          </div>
          <router-link
            :to="{ name: 'Login', params: { isShare: false } }"
            class="btn-login"
          >
            个人登录
          </router-link>
          <a
            href="https://fis.zhfundsales.com/login.html"
            class="btn-login"
            target="_blank"
            >机构登录</a
          >
        </div>
        <div v-if="token" class="top-left">
          <span class="ico ico-member" />
          <router-link :to="{ name: 'MyFundIndex' }" class="mobile">
            {{ (mobile || "").$hide() }}
          </router-link>
          <a @click="logOut" class="log-out">退出</a>
          <div style="display: inline-block;position: relative;">
            <span class="ico ico-ring" />
            <div class="xxdot" v-if="isUnread"></div>
          </div>
          <router-link :to="{ name: 'Message' }">消息中心</router-link>
        </div>
        <div class="search-box">
          <div class="kf">
            <span class="ico ico-cutomer" />
            <span class="kf-txt">客服热线: &nbsp; 400-839-1818</span>
          </div>
          <div class="search-sec">
            <input
              type="text"
              placeholder="输入基金代码或名称"
              class="search-input"
              v-model="searchKey"
              @keyup.enter="searchFund"
            />
            <span class="ico-search btn" @click="searchFund" />
          </div>
        </div>
        <div class="top-right">
          <span class="ico ico-help" />
          <router-link
            :to="{ name: 'help' }"
            exact-active-class="active"
            class="help"
          >
            帮助中心
          </router-link>
          <span class="ico ico-app" />
          <span
            class="btn"
            @mousemove="toggleCrcode(true)"
            @mouseout="toggleCrcode(false)"
            >APP下载</span
          >
          <div v-if="crcodeVisible" class="crcode-box1">
            <span class="ico ico-sort-up" />
            <img src="../static/img/crcode.png" alt="app二维码" />
          </div>
        </div>
      </div>
    </div>
    <div :class="['head',{'head2':isHead2}]" v-if="!isLogin">
      <div class="head-content">
        <div class="head-logo fl">
          <router-link to="/"><img src="@/static/img/logo-1.png" alt="众惠基金" /></router-link>
        </div>
        <div class="head-nav fr">
          <router-link to="/" exact-active-class="active">首页</router-link>
          <router-link :to="{ name: 'FundMarket' }" exact-active-class="active">
            基金超市
          </router-link>
          <router-link
            :to="{ name: 'HighFinancial' }"
            exact-active-class="active"
          >
            高端理财
          </router-link>
          <a href="https://fis.zhfundsales.com/login.html" target="_blank"
            >机构通</a
          >
          <router-link :to="{ name: 'About' }" exact-active-class="active">
            关于众惠
          </router-link>
        </div>
        <div class="logo-txt">
          <div v-if="false">
            <span style="margin-right: 1em">博取众长</span>
            <span>惠泽天下</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="nav"
      :class="className" v-if="pathtrue"
      >
      <div>
        <router-link to="/about/aboutUs">
          公司介绍
        </router-link>
        <router-link to="/about/honors">
          荣誉资质
        </router-link>
        <a @click="handleGoOut">
          从业人员公示
        </a>
        <!-- <router-link
          to="/"
          @click.native="handleGoOut"
        >
          
        </router-link> -->
        <router-link to="/about/recruitPage">
          招贤纳士
        </router-link>
        <router-link to="/about/contactUs">
          联系我们
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      searchKey: "",
      crcodeVisible: false,
      className: "",
      pathtrue: false
    };
  },
  mounted() {
    if (this.token) {
      this.Unread();
    }
  },
  computed: {
    ...mapGetters(["token", "mobile", "isUnread"]),
    isLogin() {
      return this.$route.name === "Login";
    },
    isHead2() {
      return (
        this.$route.name === "Home" || this.$route.name === "HighFinancial"
      );
    },
    isShareLogin() {
      return this.isLogin && this.$route.query.isShare == '1'
    }
  },
  methods: {
    ...mapActions(["FedLogOut", "Unread"]),
    logOut() {
      this.FedLogOut();
    },
    handleGoOut() {
      window.open(
        "https://gs.amac.org.cn/amac-infodisc/res/pof/person/personList.html?userId=1903291909100869"
      );
    },
    searchFund() {
      let key = this.searchKey.trim();
      if (!key & (key !== 0)) {
        key = "";
      }
      let page = this.$route.name;
      if (page === "FundMarket") {
        this.$set(this.$store.state, "fundSearchKey", key);
        return;
      }
      this.$router.push({
        name: "FundMarket",
        params: { searchKey: key }
      });
    },
    toggleCrcode(show) {
      this.crcodeVisible = show;
    }
    // open() {
    //   const pdfUrl =
    //     "http://192.168.31.182:9100/zhfd/sale/img/prot/20211015/22222.pdf";
    //   window.open(`/pdfs/web/viewer.html?file=${encodeURI(pdfUrl)}`, "_blank");
    // },
  },
  watch: {
    token(n) {
      if (n) {
        this.Unread();
      }
    },
    $route: {
      handler({ path }) {
        if (
          path === "/about/aboutUs" ||
          path === "/about/contactUs" ||
          path === "/about/honors" ||
          path === "/about/recruitPage" ||
          path === "/about/contactUs"
        ) {
          this.pathtrue = true;
          if (path === "/about/contactUs" || path === "/about/honors") {
            this.className = "test";
          } else {
            this.className = "";
          }
        } else {
          this.pathtrue = false;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  font: 14px/1em "microsoft yahei";
  position: relative;
  z-index: 3;
}
.nav {
  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);

  z-index: 3;
  div {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: right;
    a {
      display: inline-block;
      height: 100%;
      padding: 20px 26px;
      font-size: 18px;
      color: #fff;
      box-sizing: border-box;
      line-height: 100%;
      &.router-link-exact-active {
        background: #d7ad7f;
      }
    }
  }
  &.test {
    box-shadow: 0px 4px 12px #edf1f7;
    div a {
      color: #1f1f1f;
    }
  }
}
.xxdot {
  position: absolute;
  left: 10px;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
}
.top {
  background: #333;
  color: #fff;
  font-size: 15px;
  a {
    color: #fff;
  }
}
.top-content {
  width: 1200px;
  margin: auto;
  align-items: center;
}
.top-left {
  .log-out {
    margin: 0 1em;
    color: #b9a03c;
    border: 1px solid #b9a03c;
    padding: 2px 5px;
    border-radius: 3px;
  }
}
.user-type {
  line-height: 30px;
  float: left;
}
a.btn-login {
  border: 1px solid #947451;
  color: #bf9260;
  padding: 8px 10px;
  margin-left: 1em;
  float: left;
}
.search-box {
  background: #595959;
  height: 30px;
  padding: 20px;
}
.search-sec {
  background: #fff;
  border-radius: 2em;
  margin-right: 258px;
  padding: 0 1em;
  .ico-search {
    color: #888;
  }
}
.search-input {
  background: #fff;
  width: 348px;
  height: 30px;
}
.icon-search {
  color: #999;
}
.kf {
  float: right;
}
.kf-txt {
  line-height: 30px;
}
.ico {
  margin-right: 8px;
}

.top-right {
  line-height: 30px;
  position: relative;
  .crcode-box1 {
    position: absolute;
    background: #fff;
    padding: 12px;
    top: 40px;
    right: -90px;
    z-index: 100;
    .ico {
      position: absolute;
      left: 44%;
      top: -10px;
      color: #fff;
      font-size: 24px;
    }
    img {
      width: 190px;
      height: 190px;
    }
  }
}
.help {
  margin-right: 1em;
}
.head {
  background: #d7ad7f;
  color: #fff;
}
.head2 {
  background: rgb(215 173 127 / 80%);
}
.head-content {
  padding: 20px 0;
  width: 1200px;
  margin: auto;
}
.head-logo,
head-logo img {
  width: 175px;
  height: 50px;
}
.logo-txt {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  margin-left: 210px;
  width: 210px;
}
.head-nav > a {
  font-size: 20px;
  line-height: 46px;
  padding-bottom: 32px;
  margin-left: 45px;
  color: #fff;
}
.head-nav a.active {
  border-bottom: 2px solid #fff;
}
</style>
