import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/Layout.vue"
// import MyLayout from "@/views/my/components/Layout.vue"
import Home from '@/views/Home.vue'

import {fundMarket} from './fund-market.js'
import {highFinancial} from './highFinancial.js'
import {about} from './about.js'
import {login} from './login.js'
import {myFavors} from './myFavors.js'
import {helpCenter} from './helpCenter.js'
import {dealRecord} from './deal-record.js'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  fundMarket,
  highFinancial,
  about,
  ...login,
  myFavors,
  helpCenter,
  dealRecord,
  {
    path: '/openAccount',
    name: 'OpenAccount',
    component: () => import('../views/home/open-acct/OpenAccount.vue'),
    meta: {
      title: '开户'
    }
  },
  {
    path: '/personalDataSupply',
    name: 'PersonalDataSupply',
    component: () => import('../views/home/open-acct/PersonalDataSupply.vue'),
    meta: {
      title: '个人资料补充'
    }
  },
  {
    path: '/accountSuccess',
    name: 'AccountSuccess',
    component: () => import('../views/home/open-acct/AccountSuccess.vue'),
    meta: {
      title: '恭喜！开户成功'
    }
  },
  {
    path: '/openAccount2',
    name: 'OpenAccount2',
    component: () => import('../views/home/open-acct/OpenAccount2.vue')
  },
  {
    path: '/openAccount3',
    name: 'OpenAccount3',
    component: () => import('../views/home/open-acct/OpenAccount3.vue')
  },
  {
    path: '/openAccount4',
    name: 'OpenAccount4',
    component: () => import('../views/home/open-acct/OpenAccount4.vue')
  },
  {
    path: '/chooseBank',
    name: 'ChooseBank',
    component: () => import('../views/home/open-acct/ChooseBank.vue')
  },
  {
    path: '/achievement-lead',
    name: 'AchievementLead',
    component: () => import('@/views/home/selected-list/AchievementLead.vue'),
    meta: {
      title: '精选榜单'
    }
  },
  {
    path: '/overseas-dividend',
    name: 'OverseasDividend',
    component: () => import('@/views/home/selected-list/OverseasDividend.vue'),
    meta: {
      title: '海外分红'
    }
  },
  {
    path: '/leading-performance',
    name: 'LeadingPerformance',
    component: () => import('@/views/home/selected-list/LeadingPerformance.vue'),
    meta: {
      title: '业绩领先'
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/home/feedback/Feedback.vue'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/highFinancialAsset',
    name: 'HighFinancialAsset',
    component: () => import('../views/high-fm/HighFinancialAsset.vue')
  },
  {
    path: '/home/msg/Message',
    name: 'Message',
    component: () => import('../views/home/msg/Message.vue'),
    meta: {
      title: '消息中心'
    }
  },
  {
    path: '/updatePassword1',
    name: 'UpdatePassword1',
    component: () => import('../views/my/UpdatePassword1.vue')
  },
  {
    path: '/alterBindPhone1',
    name: 'AlterBindPhone1',
    component: () => import('../views/my/AlterBindPhone1.vue')
  },
  {
    path: '/alterBindPhone2',
    name: 'AlterBindPhone2',
    component: () => import('../views/my/AlterBindPhone2.vue')
  },
  {
    path: '/alterBindPhone3',
    name: 'AlterBindPhone3',
    component: () => import('../views/my/AlterBindPhone3.vue')
  },
  {
    path: '/PasswordAlterSuccess',
    name: 'PasswordAlterSuccess',
    component: () => import('../views/my/PasswordAlterSuccess.vue')
  },

  {
    path: '/riskAssessment1',
    name: 'RiskAssessment1',
    component: () => import('../views/my/bankManagement/RiskAssessment1.vue')
  },
  {
    path: '/riskAssessment2',
    name: 'RiskAssessment2',
    component: () => import('../views/my/bankManagement/RiskAssessment2.vue')
  },
  {
    path: '/commProblemDetail',
    name: 'CommProblemDetail',
    component: () => import('../views/home/help/CommProblemDetail.vue')
  },
  {
    path: '/updateTradePassword1',
    name: 'UpdateTradePassword1',
    component: () => import('../views/my/UpdateTradePassword1.vue')
  },
  {
    path: '/resetTradePass1',
    name: 'ResetTradePass1',
    component: () => import('../views/my/ResetTradePass1.vue')
  },
  {
    path: '/resetTradePass2',
    name: 'ResetTradePass2',
    component: () => import('../views/my/ResetTradePass2.vue')
  },
  {
    path: '/resetTradePass3',
    name: 'ResetTradePass3',
    component: () => import('../views/my/ResetTradePass3.vue')
  },
  {
    path: '/resetTradePass4',
    name: 'ResetTradePass4',
    component: () => import('../views/my/ResetTradePass4.vue')
  },
  {
    path: '/activeList',
    name: 'ActiveList',
    component: () => import('../views/ActiveList.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('../views/Information.vue')
  },
  {
    path: '/fundSchool',
    name: 'FundSchool',
    component: () => import('../views/FundSchool/FundSchool.vue')
  },
  {
    path: '/news',
    component: Layout,
    children: [{
      path: 'detail/:id',
      name: 'NewsDetail',
      props: true,
      component: () => import('@/views/news/Detail.vue'),
      meta: {
        title: '公告详情'
      }
    }]
  },
  {
    path: '/model',
    name: 'Model',
    component: () => import('../views/FundSchool/model.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }

})

export default router