import request from '@/utils/request'

/** 基金列表 */
export function fundList(data) {
  return request({
    url: '/api/sale/fundCommon/page',
    method: 'post',
    data
  })
}

/** 产品分类或主题不分页列表查询
 * @param {String} [themeName] 主题名称
 * @param {String} [prodType] 产品类型：0代表私募，1代表公募，2代表定投，3代表分类
 * @param {String} [channelType] 产品渠道：1代表APP，2代表WEB，3代表销售端
 * @param {String} [status] 0代表不显示，1代表显示
 * @param {String} [top] 置顶：0代表不置顶，1代表置顶
 */
export function themeOrProduct(prodType,channelType,status = '1',top,themeName) {
  return request({
    url: '/api/system/prod/theme/findList',
    method: 'post',
    data: {
      prodType,channelType,status,top,themeName
    }
  })
}

/** 基金公司列表
 */
export function fundCompany() {
  return request({
    url: '/api/sale/fundCommon/findCompany',
    method: 'post',
  })
}

/** 惠金宝 */
export function highest7() {
  return request({
    url: '/api/sale/trade/xjb/highest7',
    method: 'post'
  })
}

/** 主题分页列表
 * @param {Number} pageNum 页码
 * @param {Number} pageSize 每页条数	
 * @param {String} prodType 产品类型：0代表私募，1代表公募，2代表定投，3代表分类
 * @param {String} channelType 产品渠道：1代表APP，2代表WEB，3代表销售端
 * @param {String} [top] 置顶状态：0代表不置顶，1代表置顶
 */
export function themeList(pageNum,pageSize,prodType,channelType,top = null) {
  return request({
    url: '/api/system/prod/theme/page',
    method: 'post',
    data: {
      pageNum,pageSize,prodType,channelType,top,
      status:1
    }
  })
}

/** 获取基本信息
 * @param {String} fundCode 基金编码	
 */
export function baseInfo(fundCode) {
  return request({
    url: '/api/sale/prodDetails/baseInfo',
    method: 'post',
    data: {
      fundCode
    }
  })
}

/** 获取净值/累计净值列表
 * @param {String} fundCode 基金编码	
 * @param {String} searchType 1:单位净值	,2:累计净值
 * @param {String} timeRangeMark 时间范围标志1近1月;2近3月;3近6月;4近1年;5近3年;6成立以来;	
 * @param {String} startTime 开始时间,格式: yyyyMMdd
 */
export function prodUnit(fundCode,searchType,timeRangeMark,startTime) {
  return request({
    url: '/api/sale/prodDetails/prodUnit',
    method: 'post',
    data: {
      fundCode,searchType,timeRangeMark,startTime
    }
  })
}

/** 获取阶段收益
 * @param {String} secucode 基金编码	
 * @param {Number} pageNum 页码	
 * @param {Number} pageSize 每页显示	
 */
export function stageIncome(secucode,pageNum,pageSize) {
  return request({
    url: '/api/sale/prodDetails/stageIncome',
    method: 'post',
    data: {
      secucode,pageNum,pageSize
    }
  })
}

/** 查询基金概况
 * @param {String} fundCode 基金编码	
 */
export function fundOverview(fundCode) {
  return request({
    url: '/api/sale/prodDetails/fundOverview',
    method: 'post',
    data: {
      fundCode
    }
  })
}
/** 基金交易规则
 * @param {String} fundCode
 */
export function fundTradeRule(fundCode) {
  return request({
    url: 'api/system/prod/rule/find',
    method: 'post',
    data: {
      fundCode
    }
  })
}

/** 交易规则基本概况
 * @param {String} fundCode
 */
export function fundTradeInfo(fundCode) {
  return request({
    url: '/api/sale/prod/prodInfo/tradeInfo',
    method: 'post',
    data: {
      fundCode
    }
  })
}

/** 获取基金费率
 * @param {String} [fundCode] 基金代码	
 * @param {String} [rateType] 费率类型(1-申购费率 2-认购费率 3-赎回费率 4-销售服务费率 5-管理费率)
 * @param {String} [amount] 输入的金额
 */
export function fundRate(fundCode,rateType,amount) {
  return request({
    url: '/api/system/rateMag/match',
    method: 'post',
    data: {
      fundCode,rateType,amount
    }
  })
}

/** 申购 认购费率
 * @param {String} fundCode
 * @param {String} rateType 1:申购,2:认购
 */
export function fundRate2 (fundCode, rateType) {
  return request({
    url: '/api/system/rateMag/startingRate',
    method: 'post',
    data: {
      fundCode, rateType
    }
  })
}

/** 基金公告列表
 * @param {Number} pageNum 页码
 * @param {Number} pageSize 每页显示
 * @param {Number} pt_type 公告类型 （字典: sys_ann_type）
 * @param {Number} secucode 基金代码
 */
export function noticeList(pageNum,pageSize,pt_type,secucode) {
  return request({
    url: '/api/sale/prod/fundBase/findAnnFromPgAnnBul',
    method: 'POST',
    data:{
      pageNum,pageSize,pt_type,secucode
    }
  })
}

/** 基金公告详情
 * @param {String} _id 编号
 */
export function noticeDetail(_id) {
  return request({
    url: '/api/sale/prod/fundBase/fundAnnViewDetail',
    method: 'POST',
    data:{
      _id
    }
  })
}


/**************下面接口为私募基金接口************/

/** 私募基金详情
 * @param {String} fundCode 基金代码
 */
export function privateDetail(fundCode) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/detail',
    method: 'POST',
    data: {
      fundCode
    }
  })
}

/** 私募基金净值列表
 * @param {String} regnum 基金代码
 * @param {String} [startTime] 开始时间,格式:yyyyMMdd
 */
export function netValueList(regnum,startTime,pageNum = 1,pageSize = 1e4,sortAscending = true) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/netValueList',
    method: 'POST',
    data: {
      regnum,
      startTime,
      pageNum,
      pageSize,
      sortAscending
    }
  })
}

/** 私募基金季度涨幅
 * @param {String} regnum 基金代码
 */
export function quarterIncreaseList(regnum) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/quarterIncreaseList',
    method: 'POST',
    data: {
      regnum
    }
  })
}


/** 私募基金年度涨幅
 * @param {String} regnum 基金代码
 */
export function yearIncreaseList(regnum) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/yearIncreaseList',
    method: 'POST',
    data: {
      regnum
    }
  })
}

/** 私募基金基金概况
 * @param {String} fundCode 基金代码
 */
export function fundSurvey(fundCode) {
  return request({
    url: '/api/sale/prod/prodInfo/fundSurvey',
    method: 'POST',
    data: {
      fundCode
    }
  })
}

/** 私募基金经理列表
 * @param {String} regnum 基金代码
 */
export function personList(regnum) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/personList',
    method: 'POST',
    data: {
      regnum
    }
  })
}

/** 私募基金公告列表
 * @param {String} regnum 基金代码
 * @param {String} contentType 公告类型
 */
export function pubInfoList(regnum,contentType) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/pubInfoList',
    method: 'POST',
    data: {
      regnum,
      contentType
    }
  })
}

/** 私募基金公告详情
 * @param {String} serialNumber 序号
 */
export function pubInfoDetail(serialNumber) {
  return request({
    url: '/api/sale/appproddetails/prodSfDetail/pubInfoDetail',
    method: 'POST',
    data: {
      serialNumber
    }
  })
}

/** 私募基金分红概况
 * @param {String} regnum 基金代码
 */
export function pofShare(fundCode) {
  return request({
    url: '/api/sale/prod/prodInfo/pofShare',
    method: 'POST',
    data: {
      fundCode
    }
  })
}