/** 关于众惠 */
export const about = 
{
  path: '/about',
  name: 'About',
  component: () => import('../views/about/index'),
  redirect: {
    name: 'AboutUs'
  },
  meta: {
    title: '关于众惠'
  },
  children: [{
      path: 'aboutUs',
      name: 'AboutUs',
      component: () => import('../views/about/aboutUs.vue'),
      meta: {
        title: '荣誉资质'
      },
    },
    {
      path: 'honors',
      name: 'Honors',
      component: () => import('../views/about/honors.vue'),
      meta: {
        title: '荣誉资质'
      },
    },
    {
      path: 'recruitPage',
      name: 'RecruitPage',
      component: () => import('../views/about/recruitPage.vue'),
      meta: {
        title: '招贤纳士'
      },
    },
    {
      path: 'contactUs',
      name: 'ContactUs',
      component: () => import('../views/about/contactUs.vue'),
      meta: {
        title: '联系我们'
      },
    },
  ]
}