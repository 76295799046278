<template>
  <div class="step">
    <div class="step-wrap">
      <div
        v-for="(item, i) in steps"
        :key="item + i"
        class="step-item"
        :class="getClassName(i)"
        :style="{width: width + '%'}"
      >
        <img
          v-if="i < (index-1)"
          src="@/assets/images/stepDone.png"
        >
        <div v-else>
          {{ i + 1 }}
        </div>
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'ZhStep',
		props:{
			steps: {
				type: Array,
				default: () => ['步骤1', '步骤2', '步骤3']
			},
			index: {
				type: Number,
				default: 1
			}
		},
		computed:{
			width() {
				return 100 / this.steps.length
			},
		},
		methods: {
			getClassName(i) {
				if (i < (this.index-1)) {
					return 'active'
				} else if (i === (this.index-1)) {
					return 'cur-active'
				}
			}
		},
	}
</script>

<style scoped lang="scss">
	div {
		box-sizing: border-box;
	}
	.step {
		width: 100%;
		background: #FFFFFF;
		
		.step-wrap {
			margin-top: 18.76px;
			width: 100%;
			height: 144px;
			padding: 0 25px;
			padding-top: 36px;
			display: flex;
			justify-content: center;
			.step-item {
				width: 33.33%;
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				div {
					width: 51px;
					height: 51px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 28px;
					font-weight: bold;
					color: #FFFFFF;
					border-radius: 50%;
					border: 4px solid #E0E2E8;
					color: #BDC0CB;
					box-sizing: border-box;
				}
				span {
					margin-top: 18px;
					font-size: 14px;
					color: #BDC0CB;
				}
				img {
					width: 51px;
					height: 51px;
				}
				&::before {
					content: '';
					display: inline-block;
					width: calc(50% - 31.5px);
					height: 2px;
					background: #E9E2DB;
					position: absolute;
					left: 0;
					top: 21.46px;
				}
				
				&::after {
					content: '';
					display: inline-block;
					width: calc(50% - 31.5px);
					height: 2px;
					background: #E9E2DB;
					position: absolute;
					right: 0;
					top: 21.46px;
				}
				&.active {
					div {
						border: 4px solid #CE9B63;
						color: #CE9B63;
					}
					span {
						color: #CE9B63;
					}
					&::before {
						background:  #CE9B63;
					}
					&::after{
						background:  #CE9B63;
					}
				}
				&.cur-active {
					div {
						border: 4px solid #CE9B63;
						color: #CE9B63;
					}
					span {
						color: #CE9B63;
						font-weight: bold;
					}
					&::before {
						background:  #CE9B63;
					}
				}
				
				&:first-child {
					&::before {
						display: none;
					}
				}
				&:last-child {
					&::after {
						display: none;
					}
				}
			}
		}
	}
</style>
