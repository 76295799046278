import request from '@/utils/request'

// 查询客户经理信息
export function findmanager(data) {
  return request({
    url: '/api/sale/customer/findmanager',
    method: 'POST',
  data
  })
}

// 鉴权
export function loginToken(data) {
  return request({
    url: '/im/loginToken',
    method: 'POST',
  data
  })
}
