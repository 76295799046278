<template>
<section class="login" v-if="!token || showmodel">
    <div class="tab" v-if="!shareLogin">
      <span :class="['btn', { current: loginType === 2 }]" @click="loginType = 2">验证码登录</span>
      <span :class="['btn', { current: loginType === 1 }]" @click="loginType = 1">密码登录</span>
    </div>
    <div class="login-box">
      <div class="title">
        <p>
          构建
          <span class="orange">数字化财富管理</span>
          新生态
        </p>
        <small class="orange"><small>让投资触手可及</small></small>
      </div>
      <div class="mobile-box">
        <div class="flex-between">
          <span class="pre">中国(+86)></span>
          <input class="login-input" type="text" placeholder="手机号" @keyup="valiMobile" v-model="loginData.mobile" maxlength="11">
        </div>
        <p class="login-error">{{ loginError.mobile }}</p>
      </div>
      <p class="pwd-box" v-show="loginType === 1">
        <input class="login-input" :type="pwdType" placeholder="密码" v-model="loginData.pwd" maxlength="16" @keyup="checkPwd">
        <span :class="['btn btn-eye', pwdShow ? 'ico-eye-open' : 'ico-eye-close']" @click="togglePwdSee" />
      </p>
      <p class="login-error" v-show="loginType === 1">{{ loginError.pwd }}</p>

      <div class="msgcode-box" v-show="loginType === 2">
        <div class="flex-between">
          <input class="login-input" type="text" placeholder="验证码" v-model="loginData.msgCode" maxlength="6">
          <input type="button" :value="msgCodeTxt" :class="['send-code', { disable: !msgSendable }]" @click="sendMsgCode">
        </div>
        <p class="login-error">{{ loginError.msgCode }}</p>
      </div>

      <div class="login-remember flex-between" v-show="loginType === 1">
        <div>
          <!-- <input type="checkbox" v-model="rememberPwd"> -->
          <el-checkbox v-model="rememberPwd" class="rememberPwd" />
          <span class="btn" @click="rememberPwd = !rememberPwd">记住密码</span>
        </div>
        <div><router-link to="/myFavors/accountManage/safetyCentre">忘记密码?</router-link></div>
      </div>

      <div class="" v-if="loginType === 2"><input type="button" value="登录注册" class="btn-login union-login" @click="login"></div>
      <div class="flex-between" v-else>
        <input type="button" value="登 录" class="btn-login" @click="login">
        <input type="button" value="注 册" class="btn-login btn-reg" @click="goReg">
      </div>

      <div style="margin-top: 1em;">
        <el-checkbox v-model="checked" class="login-checked">登录即为同意</el-checkbox>
        <small>
          <!-- <a :href="agree1" class="orange">《用户服务协议》</a> -->
          <a v-for="(item,i) in registerdata" :key="i" @click="item.protUrl?toPdf(item.protUrl):tofundnotice(item.id)" class="orange">{{item.protNameDesc||'《'+item.infotitle+'》'}}
            <span v-if="i<registerdata.length-1">、</span>
            </a>
        </small>
      </div>
    </div>

    <Models :btn-text="btnText" :showmodel="showmodel" @cancels="showmodel = false" @showdelte="showmodel = false" @confirms="confirms" @showmodels="showmodel=false">
      <div class="modal-box">
        <div class="title">
          为确保账号安全
        </div>
        <div class="content">
          请立即重置交易密码
        </div>
      </div>
    </Models>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getDicts } from "@/api/dict.js";
import { sendMsgCode, valiMsgCode, noPwdReg,channelCount } from "@/api/user.js";
import { protPage } from "@/api/other/index.js";
import { setToken, setMobile, setUserId } from "@/utils/auth.js";
import Models from "@/components/Models";
export default {
  name: "LoginIn",
  components: {
    Models
  },
  props: {
    isHome: Boolean,
    isShare: Boolean
  },
  data() {
    return {
      showmodel: false,
      btnText: {
        comfirm: "去重置"
      },
      checked: false,
      loginType: 2,
      pwdShow: false,
      msgCodeTxt: "发送验证码",
      msgSendable: true,
      loginData: {
        mobile: "",
        pwd: "",
        msgCode: ""
      },
      loginError: {
        mobile: "",
        pwd: "",
        msgCode: ""
      },
      rememberPwd: false,
      registerdata:[],//协议
      uuid: ""
    };
  },
  computed: {
    ...mapGetters(["token"]),
    pwdType() {
      return this.pwdShow ? "text" : "password";
    },
    popChannelId() {
      return this.$route.query.popChannelId;
    },
    shareLogin() {
      return this.isShare == '1'
    }
  },
  created() {
    this.loginData.mobile = this.$route.params.mobile || "";
    protPage({
      pageNum: 1,
      pageSize: 100,
      protLink: "1",
      protType: "1"
    }).then(result => {
      this.registerdata = result.data.data || [];
    });
  },
  methods: {
    ...mapMutations(["SET_TOKEN", "SET_UID", "SET_MOBILE"]),
    ...mapActions(["Login", "GetInfo"]),
    confirms() {
      this.showmodel = false;
      this.$router.push({
        name: "ResetTradePW"
      });
    },
    /** 切换登录密码显示方式 */
    togglePwdSee() {
      this.pwdShow = !this.pwdShow;
    },
    /** 发送登录验证码 */
    sendMsgCode() {
      let mobile = this.loginData.mobile;
      if (!/^1[3-9]\d{9}$/.test(mobile)) {
        this.loginError.mobile = "请填写正确的手机号!";
        return;
      }
      let able = this.msgSendable;
      if (!able) {
        return;
      }
      this.msgSendable = false;
      this.msgCodeTxt = "正在发送……";
      this.loginError = {};
      let msgType = this.isShare ? "1" : "2";
      sendMsgCode(mobile, msgType)
        .then(() => {
          let count = 60;
          this.msgCodeTxt = "重新发送(" + count + ")";
          let interval = setInterval(() => {
            count--;
            if (count === 0) {
              this.msgCodeTxt = "发送验证码";
              this.msgSendable = true;
              clearInterval(interval);
            } else {
              this.msgCodeTxt = "重新发送(" + count + ")";
            }
          }, 1000);
          this.loginError.msgCode = "发送成功,请查看您的手机短信!";
        })
        .catch(error => {
          this.msgSendable = true;
          this.msgCodeTxt = "发送验证码";
          this.loginError.msgCode = error;
          if (error === "当前手机号未注册") {
            this.$router.push({
              name: "Reg",
              params: { mobile }
            });
          }
          if (error === "当前手机号已注册") {
            this.$router.push({
              name: "Login",
              params: { mobile }
            });
          }
        });
    },
    /** 验证登录手机格式 */
    valiMobile(e) {
      this.loginData.mobile = e.target.value.replace(/[^\d]/g, "");
    },
    /** 登录 */
    login() {
      if (!this.checked) {
        this.$message({
          message: "您还未勾选协议",
          type: "warning"
        });
        return;
      }
      this.loginError = {
        mobile: "",
        pwd: "",
        msgCode: ""
      };
      let { mobile, pwd, msgCode } = this.loginData;
      mobile = (mobile || "").toString();
      pwd = (pwd || "").toString();
      msgCode = (msgCode || "").toString();
      if (!mobile) {
        this.loginError.mobile = "请填写手机号!";
        return;
      }
      if (!/^1[3-9]\d{9}$/.test(mobile)) {
        this.loginError.mobile = "请填写正确的手机号!";
        return;
      }
      if (this.loginType === 1 && !pwd) {
        this.loginError.pwd = "请填写登录密码!";
        return;
      }
      if (this.loginType === 1 && pwd.length < 6) {
        this.loginError.pwd = "登录密码错误!";
        return;
      }
      if (this.loginType === 2 && !msgCode) {
        this.loginError.msgCode = "请填写短信验证码!";
        return;
      }

      if(this.isShare && this.$route.query.channel == '4') {
        channelCount(this.$route.query.popChannelId,null,1).catch(() => {})
      }
      if (this.loginType === 2 && this.isShare) {
        valiMsgCode(mobile, msgCode, "1")
          .then(result => {
            this.uuid = result.data;
            noPwdReg(
              mobile,
              this.uuid,
              {
                channel:this.$route.query.channel,
                popChannelId:this.$route.query.popChannelId,
                invitorId:this.$route.query.invitorId,
                inviteCode:this.$route.query.inviteCode
              }
            )
              .then(() => {
                this.$message.success("注册成功!");
                this.$router.push({ name: "Login" });
              })
              .catch(error => {
                this.loginError.mobile = error;
              });
          })
          .catch(error => {
            this.loginError.msgCode = error;
          });
        return;
      }

      let params = {
        idOrPh: mobile,
        loginType: this.loginType,
        clientSource: "1"
      };
      if (this.loginType === 1) {
        params.password = pwd;
      } else {
        params.phCode = msgCode;
      }
      params.remember = this.rememberPwd;

      this.Login(params)
        .then(() => {
          // 判断是否为老用户  老用户则引导跳转到重置交易密码页面
          const userInfo =
            JSON.parse(localStorage.getItem("customerInfo")) || {};
          if (userInfo.oldUserFlag === "1") {
            this.showmodel = true;
            return;
          }

          if (!this.isHome) {
            this.$router.push("/");
          }
          // this.GetInfo(params.remember).then(() => {
          //   // 判断是否为老用户  老用户则引导跳转到重置交易密码页面
          //   const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
          //   if (userInfo.oldUserFlag === '1') {
          //     this.showmodel = true
          //     return
          //   }

          //   if (!this.isHome) {
          //     this.$router.push('/');
          //   }
          // });
        })
        .catch(error => {
          this.loginError.mobile = error;
        });
    },
    /** 注册 */
    goReg() {
      this.$router.push("reg");
    },
    /** 检查密码规则 */
    checkPwd(e) {
      let value = e.target.value;
      value = value.replace(/^([a-zA-Z\d]*)?(.*)?/, "$1");
      this.loginData.pwd = value;
    }
  }
};
</script>

<style lang="less" scoped>
.modal-box {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    color: #000000;
    text-align: left;
  }

  .content {
    margin: 24px 10px 0;
    font-style: normal;
    font-weight: 290;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }
}
.orange {
  color: #deb87b;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ce9b63;
  border-color: transparent;
}

/deep/.el-checkbox__inner:hover {
  border-color: #ce9b63;
}

/* 
  .banner-sec { position: relative; }
  .login{ width: 100%; height:480px; background: #000 url(../static/img/banner/home%20banner.jpg) center no-repeat; overflow: hidden;}
  */
.login {
  /* position: absolute; top: 2em; right:1em; */
  background: #fff;
  width: 380px;
  box-shadow: 0 0 5px #ddd;

  .tab {
    display: flex;
  }

  .tab > span {
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #deb87b;
    background: #3d3d3d;
    padding: 16px;

    &.current {
      background: #f7f8f9;
    }
  }

  .login-box {
    padding: 1em 2em;

    .title {
      font: bold 20px "microsoft yahei";
      text-align: center;
    }

    .mobile-box {
      margin: 1em 0;

      & > div {
        border: 1px solid #928c8c;
        border-radius: 6px;
      }

      .pre {
        border-right: 1px solid #928c8c;
        width: 105px;
        padding: 12px;
      }

      .login-input {
        border: none;
        margin: 0;
      }
    }

    .pwd-box {
      position: relative;
    }

    .login-input {
      border: 1px solid #928c8c;
      padding: 1.2em;
      border-radius: 6px;
      width: 90%;
    }
  }

  .login-error {
    color: #de0000;
  }

  .btn-eye {
    position: absolute;
    font-size: 30px;
    top: 10px;
    right: 11px;
  }

  .rememberPwd {
    margin-right: 10px;
  }

  .login-checked {
    margin-right: 5px;
  }

  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: inherit;
  }

  .msgcode-box {
    align-items: baseline;
    margin-bottom: 1em;
  }

  .send-code {
    padding: 17px 10px;
    border-radius: 4px;
    background: #f5cea9;
    color: #d09e68;
    margin-left: 1em;
  }

  .disable {
    background: #eee;
    color: #333;
  }

  .login-remember {
    padding: 1em 0;
  }

  .btn-login,
  .btn-reg {
    color: #d09e68;
    font: bold 1.2em "microsoft yahei";
    width: 47%;
    padding: 13px;
    background: #f5cea9;
    border-radius: 6px;
  }

  .btn-reg {
    background: #fff;
    border: 1px solid #deb87b;
  }

  .union-login {
    width: 100%;
  }
}
</style>
