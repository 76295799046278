let f = () => {
	// 当前判断主要是针对于私募
	const clientHgFlag = JSON.parse(localStorage.getItem("clientHgFlag"))
	let flag = true
	// if (!Authorization) {
	// 	flag = false
	// 	this.$confirm('您未登录，请登录！', '提示', {
	// 		confirmButtonText: '确定',
	// 		cancelButtonText: '取消',
	// 		type: 'warning'
	// 	}).then(() => {
	// 		this.$router.push({ name: "Login"})
	// 	}).catch(() => {
			
	// 	});
	// 	return flag
	// }
	// console.log('---调用没得',clientHgFlag.confirm)
	
	if (clientHgFlag.confirm!='1') {
		flag = false
		
		return flag
	}
	if (!clientHgFlag.priRiskLevel) {
		flag = false
		
		return flag
	}
	return flag
}

export default f