import request from '@/utils/request'

// 登录方法
export function login(data) {
  return request({
    url: '/api/sale/customer/login',
    method: 'post',
    data
  })
}


// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout(data) {
  return request({
    url: '/system/auth/logout',
    method: 'post',
    data
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/system/auth/captchaImage',
    method: 'get'
  })
}
// 获取短信验证码
export function getCodeSms(data) {
  return request({
    url: '/api/sale/sms/routineSend',
    method: 'post',
    data
  })
}
// 校验短信验证码
export function checkCodeSms(data) {
  return request({
    url: '/api/sale/sms/checkVerifCode',
    method: 'post',
    data
  })
}

// 是否有未读消息
export function unread(data) {
  return request({
    url: '/api/sale/clientNotice/unread',
    method: 'post',
  data
  })
}

