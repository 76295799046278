<template>
  <div
    v-if="showmodel"
    class="showmodels_"
    @click="showmodels"
  >
    <div
      class="cont_"
      @click.stop="() => {}"
    >
      <div class="top_img">
        <span
          style="font-size:33px;color:#E45247;"
          class="iconfont f_s"
        >&#xe669;</span>
        <a v-show="btnText.cancel" @click.stop="showdelte"><span
          style="font-size:24px;color:#E0E2E8;"
          class="iconfont f_s"
        >&#xe65b;</span></a>
      </div>
      <slot />
      <div
        class="bottom_btn"
        :style="{
            'justify-content': btnText.cancel ? 'space-between' : 'flex-end'
        }"
      >
        <a
          class="l_btn"
          @click.stop="cancels"
          v-show="btnText.cancel"
        >{{ btnText.cancel }}</a>
        <a
          class="r_btn"
          @click.stop="confirms"
        >{{ btnText.comfirm }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "DinDetails",
    props: {
        showmodel: {
            type: Boolean,
            default: false
        },
        btnText: {
            type: Object,
            default: () => {
                return {
                    cancel: "取消",
                    comfirm: "确认",
                }
            }
        }
    },
    watch: {
        showmodel(val, old) {
            console.log("val", val, old);
            if (val) {
                this.stopMove();
            } else {
                this.Move();
            }
        }
    },
    methods: {
        cancels() {
            this.$emit("cancels");
        },
        confirms() {
            this.$emit("confirms");
        },
        showdelte(){
            this.$emit("showdelte");
        },
        showmodels() {
            this.$emit("showmodels");
        },
        stopMove() {
            // let m = function (e) {
            //     e.preventDefault();
            // };
            // document.body.style.overflow = "hidden";
            // document.addEventListener("touchmove", m, {
            //     passive: false
            // });
        },
        Move() {
            // let m = function (e) {
            //     e.preventDefault();
            // };
            // document.body.style.overflow = "";
            // document.addEventListener("touchmove", m, {
            //     passive: true
            // });
        }
    }
};
</script>

<style lang="less" scoped>
.showmodels_ {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);

    .cont_ {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        z-index: 99999;
        width: 336px;
        padding: 27px;
        border-radius: 16px;
        box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.16);

        .top_img {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }

        .bottom_btn {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                display: inline-block;
                text-align: center;
                font-size: 14px;
            }

            .l_btn {
                width: 163px;
                height: 40px;
                border: 1px solid #e0e2e8;
                border-radius: 2px;
                color: #8691a8;
                line-height: 40px;
            }

            .r_btn {
                width: 165px;
                height: 42px;
                background: #e45247;
                border-radius: 2px;
                color: #fff;
                font-weight: bold;
                line-height: 42px;
            }
        }
    }
}
</style>
