/** 登录注册 */
export const login = 
[
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/reg/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/reg',
    name: 'Reg',
    component: () => import('@/views/reg/Index.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/resetPasswordOne',
    name: 'ResetPasswordOne',
    component: () => import('@/views/reg/ResetPasswordOne.vue'),
    meta: {
      title: '重置密码'
    }
  },
]