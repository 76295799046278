/** 字符串扩展方法 */

/** 将yyyyMMdd格式的日期格式化
 * @param {String} [format:'yyyy-MM-dd'] 格式,支持 yyyy,MM,dd 参数
 */
String.prototype.$formatDate = function(format) {
  let that = this.toString()
  format = format || 'yyyy-MM-dd'
  let yyyy = that.substr(0,4)
  let MM = that.substr(4,2)
  let dd = that.substr(6,2)
  return format.replace('yyyy',yyyy).replace('MM',MM).replace('dd',dd)
}

/** 移除右侧字符
 * @param {String} _char 需要移除的字符
 */
String.prototype.$trimRight = function(_char) {
	let reg = new RegExp('^(.*?)('+_char+'+?)$')
	return this.replace(reg,'$1')
}

/** 移除数字末尾0
 * @param {Number} num 小数位显示
 */
String.prototype.$trimZero = function(num) {
	if(this.indexOf('.')===-1) {
		return this
	}
	let value = this.$trimRight('0').$trimRight('[.]')
  
  if(this.indexOf('.') > -1 && num) {
    value = parseFloat(value).toFixed(num).toString()
  }
  return value
}


/** 隐藏字符串中部分字符 */
String.prototype.$hide = function() {
	if(this.length === 1) {
    return this
  }
  if(this.length === 2) {
    return this.substr(0,1)+'*'
  }
  let hide = this.length / 3
  return this.substr(0,hide)+''.padStart(hide,'*')+this.substr(hide*2)
}