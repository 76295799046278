import request from '@/utils/request'

/** 根据字典类型查询字典数据信息
 * @param {String} dictType 字典类型
 */
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}