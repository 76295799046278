/** 将大数字格式化成亿、万格式
 * @param {Number} dotNum 保留小数位,默认2
 */
Number.prototype.$formatBig = function(dotNum = 2){
  if(this>1e8) {
    return (this/1e8).toFixed(dotNum) + '亿'
  }
  if(this>1e4) {
    return (this/1e4).toFixed(dotNum) + '万'
  }
  return this.toString()
}

/** 移除数字末尾0
 * @param {Number} num 小数位显示
 */
Number.prototype.$trimZero = function (num) {
  return this.toString().$trimZero(num)
}