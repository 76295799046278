import Cookies from 'js-cookie'

const TokenKey = 'zhfd-sale'
const mobileKey = 'zhfd-mobile'
const userNameKey = 'zhfd-userName'
const userIdKey = 'zhfd-userId'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token,remember) {
  let expires = remember ? {expires: 7} : null
  return Cookies.set(TokenKey, token, expires)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getMobile() {
  return Cookies.get(mobileKey)
}

export function setMobile(mobile,remember) {
  let expires = remember ? {expires: 7} : null
  return Cookies.set(mobileKey, mobile, expires)
}

export function removeMobile() {
  return Cookies.remove(mobileKey)
}

export function getUserName() {
  return Cookies.get(userNameKey)
}

export function setUserName(userName,remember) {
  let expires = remember ? {expires: 7} : null
  return Cookies.set(userNameKey, userName, expires)
}

export function removeUserName() {
  return Cookies.remove(userNameKey)
}

export function getUserId() {
  return Cookies.get(userIdKey)
}

export function setUserId(userId,remember) {
  let expires = remember ? {expires: 7} : null
  return Cookies.set(userIdKey, userId, expires)
}

export function removeUserId() {
  return Cookies.remove(userIdKey)
}
