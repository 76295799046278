/** 日期格式化
 * @param {String} [format:'yyyy-MM-dd'] 格式,支持 yyyy,MM,dd,HH,mm,ss 参数
 */
Date.prototype.$format = function(format = 'yyyy-MM-dd') {
  let yyyy = this.getFullYear()
  let M = this.getMonth()+1
  let d = this.getDate()
  let H = this.getHours()
  let m = this.getMinutes()
  let s = this.getSeconds()
  let yy = yyyy.toString().substr(2)
  let MM = M < 10 ? M.toString().padStart(2,'0') : M
  let dd = d < 10 ? d.toString().padStart(2,'0') : d
  let HH = H < 10 ? H.toString().padStart(2,'0') : H
  let mm = m < 10 ? m.toString().padStart(2,'0') : m
  let ss = s < 10 ? s.toString().padStart(2,'0') : s
  return format.replace('yyyy',yyyy).replace('yy',yy)
				.replace('MM',MM).replace('M',M)
				.replace('dd',dd).replace('d',d)
				.replace('HH',HH).replace('H',H)
				.replace('mm',mm).replace('m',m)
				.replace('ss',ss).replace('s',s)
}

/** 获取日期星期几 */
Date.prototype.$week = function(){
  let week = this.getDay()
  switch (week){
    case 1:
      return '星期一'
    case 2:
      return '星期二'
    case 3:
      return '星期三'
    case 4:
      return '星期四'
    case 5:
      return '星期五'
    case 6:
      return '星期六'
    default:
      return '星期日'
  }
}

/** 获取确认日期 */
Date.prototype.$confirmDate = function() {
  if(this.getDay() === 5) {
    this.setDate(this.getDate()+2)
  }
  if(this.getDay() === 6) {
    this.setDate(this.getDate()+2)
  }
  if(this.getDay() === 0) {
    this.setDate(this.getDate()+1)
  }
  this.setDate(this.getDate()+1)
}