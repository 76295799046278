<template>
  <div class="zh-radio">
    <img
      v-if="label === value"
      src="@/assets/images/myFavors/radio1.png"
      @click="handleClick"
    >
    <img
      v-else
      src="@/assets/images/myFavors/radio2.png"
      @click="handleClick"
    >
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ZhRadio',
    props: {
      value: {
        type: [String, Number, Boolean],
        required: true,
      },
      label: {
        type: [String, Number, Boolean],
        default: ''
      }
    },
    methods: {
      handleClick() {
        this.$emit('input', this.label)
        this.$nextTick(() => {
          this.$emit('change', this.label)
        })
      }
    },
  }
</script>

<style lang="less" scoped>
.zh-radio {
  // width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  margin-right: 54px;
  &>img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &>div {
    flex: 1;
    font-size: 14px;
    color: #25293D;
  }
}
</style>