<template>
<section class="right-bar">
<a class="item" @click="onlineserver">
<span class="ico-cutomer" />
<p>在线客服</p>
</a>
<router-link class="item" :to="{name:'fixedCal'}">
<span class="ico-calc" />
<p>定投计算</p>
</router-link>
<a class="item down-app" @mousemove="toggleCrcode(true)" @mouseout="toggleCrcode(false)">
<span class="ico-app" />
<p>下载APP</p>
<p v-show="crcodeVisible" class="crcode-box">
	<span class="ico ico-next" />
	<img src="../static/img/crcode.png" alt="app二维码">
</p>
</a>
<a class="item" @click="goTop">
<span class="ico-go-top" />
<p>回到顶部</p>
</a>
<Models :btn-text="btnText"  :showmodel="showmodel" @cancels="showmodel = false" @showdelte="showmodel = false" @confirms="confirms" @showmodels="showmodel=false">
<div class="modal-box">
<div class="title">
	{{ modalData.modalTitle }}
</div>
<div class="content">
	{{ modalData.content }}
</div>
</div>
</Models>
</section>
</template>

<script>
import { findmanager,loginToken } from "@/api/onLineServe.js"
import { getToken } from '@/utils/auth'
import Models from "@/components/Models";
import {imIp, imIpWeb} from '@/utils/ip.js'
import axios from 'axios'

export default {
	name: 'RightBar',
	components:{Models},
	data() {
	return {
	crcodeVisible: false,
	showmodel:false,
	btnText:{
		cancel: "取消",
		comfirm: "去登录",
	},
	modalData:{
		modalTitle: "未登录",
		content: "请先登录，即可在线服务。"
	}

	}
	},
	methods: {
	onlineserver(){
	let token = getToken();
	if(token){
		let customerInfo = JSON.parse(localStorage.customerInfo);
		axios.defaults.headers['Authorization'] = token;
		axios.post(`${imIp}/im/loginToken`,{userId:customerInfo.mobileTel,token,im:true}).then(r=>{
			if(r.data.code==200){
				let socketToken = r.data.data;
				//获取客户经理信息
				findmanager({userId:customerInfo.userId}).then(r1=>{
					if(r1.data.jobNo){
						window.open(`${imIpWeb}/chatroom1?clientSource=0&userId=${customerInfo.userId}&socketToken=${socketToken}&token=${token}`)
					}else if(!r1.data.jobNo){
						this.$message.warning('抱歉！需绑定客户经理才能进行咨询。')
					}
				});
			}
		})
		// return;
		// loginToken({userId:customerInfo.mobileTel,token,im:true}).then(r=>{
		// 	if(r.code==200){
		// 		let socketToken = r.data;
		// 		//获取客户经理信息
		// 		findmanager({userId:customerInfo.userId}).then(r1=>{
		// 			if(r1.data.jobNo){
		// 				window.open(`${imIp}/chatroom1?clientSource=0&userId=${customerInfo.userId}&socketToken=${socketToken}&token=${token}`)
		// 			}else if(!r1.data.jobNo){
		// 				this.$message.warning('抱歉！需绑定客户经理才能进行咨询。')
		// 			}
		// 		});
		// 	}
		// })
	}else{
		this.showmodel = true;
	}
	},
	confirms(){
	this.$router.push('/login')
	this.showmodel = false;
	},
	/** 回顶部 */
	goTop() {
	this.$nextTick(() => {
		let total = document.documentElement.scrollTop
		let interval = setInterval(() => {
			if(total <= 1) {
				document.documentElement.scrollTo(0,0)
				clearInterval(interval)
			} else {
				let distance = total / 50
				distance =  distance < 1 ? 1 : distance
				total -= distance
				document.documentElement.scrollTop = total
			}
		},1)
	})
	},
	/** 切换二维码显示 */
	toggleCrcode(show) {
	this.crcodeVisible = show
	}
	}
}
</script>

<style lang="less" scoped>
.modal-box {
.title {
font-style: normal;
font-weight: bold;
font-size: 19px;
line-height: 24px;
color: #000000;
text-align: left;
}

.content {
margin: 24px 10px 0;
font-style: normal;
font-weight: 290;
font-size: 16px;
line-height: 21px;
color: #000000;
}
}
.right-bar{ position:fixed; bottom: 30px; right: 10px; width: 50px; padding:15px 5px 5px 5px; border-radius: 8px; box-shadow: 0 0 5px #eee; background: #fff; z-index:101;
.item{ border-bottom: 1px solid #C0C0C0; color:#999; font-size: 40px; padding-bottom: 10px; margin-bottom: 15px; display: block; text-align: center; user-select: none; }
.item:last-child{ border-bottom: none;}
p{ font-size:12px; line-height: 1.6em;}
.down-app{ position: relative; z-index: 2;
.crcode-box{ position: absolute; background: #fff; padding:12px; top:-70px; right:70px; z-index: 2; border: 1px solid #ccc;
	.ico{position: absolute; right:-20px; top:44%; color:#333; font-size: 24px;}
	img{width: 190px; height:190px;}
}
}
}
</style>
