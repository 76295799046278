/** 帮助中心 */
export const helpCenter = 
{
  path: '/helpCenter',
  name: 'help',
  component: () => import('@/views/other/HelpCenter.vue'),
  redirect: {
    name: 'CommProblem'
  },
  meta: {
    title: '众惠首页'
  },
  children: [{
      path: 'advice',
      name: 'advice',
      component: () => import('@/views/other/advice.vue'),
      meta: {
        // title: '意见反馈'
        title: '帮助中心',
        type: 1,
        name: 'advice',
        titles: 'HelpCenter'
      }
    },
    {
      path: 'help',
      // name: 'help',
      name: 'CommProblem',
      component: () => import('@/views/other/help.vue'),
      meta: {
        title: '帮助中心',
        type: 1,
        name: 'CommProblem',
        titles: 'HelpCenter'
      }
    },
    {
      path: 'helpDetail',
      name: 'helpDetail',
      component: () => import('@/views/other/helpDetail.vue'),
      meta: {
        title: '帮助中心详情',
        type: 1,
        name: 'helpDetail',
        titles: 'HelpCenter'
      }
    },
  ]
}