import { login, logout, getInfo,unread } from '@/api/login'
import { confirmInfo } from "@/api/addfund.js";
import { getToken, setToken, removeToken,getMobile,setMobile,removeMobile,getUserName,setUserName,removeUserName,getUserId,setUserId,removeUserId } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    uid: getUserId(),
    name: getUserName(),
    mobile: getMobile(),
    avatar: '',
    roles: [],
    permissions: [],
    isUnread:true,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_UID: (state, userId) => {
      state.uid = userId
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_MOBILE: (state, mobile) => {
      state.mobile = mobile
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    Unread1:(state,status)=>{
      state.isUnread = status;
    }
    // SET_ROLES: (state, roles) => {
    //   state.roles = roles
    // },
    // SET_PERMISSIONS: (state, permissions) => {
    //   state.permissions = permissions
    // }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          console.log('res', res.data.customerInfo);
          const user = res.data.customerInfo
          const avatar = !user.avatar ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
          commit('SET_UID',user.userId)
          commit('SET_NAME', user.clientName||'新用户')
          commit('SET_MOBILE',user.mobileTel)
          commit('SET_AVATAR', avatar)
          // 添加用户信息缓存
          window.localStorage.setItem('customerInfo', JSON.stringify(user))
          confirmInfo({
            userId: user.userId
          }).then(ress=>{
            window.localStorage.setItem("clientHgFlag", JSON.stringify(ress.data));
          })
          // customerInfo
          const { token } = res.data
          setToken(token,userInfo.remember)
          setUserId(user.userId,userInfo.remember)
          setMobile(user.mobileTel,userInfo.remember)
          setUserName(user.clientName||'新用户',userInfo.remember)
          commit('SET_TOKEN', token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    
    // 获取用户信息   临时调用当前方法获取最新用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.user
          const avatar = !user.avatar ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
          // console.log(' res.permissions', res.permissions);

          // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          // commit('SET_ROLES', res.roles)

          // commit('SET_PERMISSIONS', res.permissions)
          // } else {
          // commit('SET_ROLES', ['ROLE_DEFAULT'])
          // }
          // setUserId(user.userId,remember)
          // setMobile(user.mobileTel,remember)
          // setUserName(user.clientName||'新用户',remember)
          commit('SET_UID',user.userId)
          commit('SET_NAME', user.clientName||'新用户')
          commit('SET_MOBILE',user.mobileTel)
          commit('SET_AVATAR', avatar)
          console.log(res)
          // 刷新用户信息缓存
          window.localStorage.setItem('customerInfo', JSON.stringify(res.user))
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          // commit('SET_ROLES', [])
          // commit('SET_PERMISSIONS', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')        
        commit('SET_UID','')
        commit('SET_MOBILE', '')
        commit('SET_NAME','')
        removeToken()
        removeUserId()
        removeMobile()
        removeUserName()
        localStorage.removeItem('customerInfo')
        localStorage.removeItem('clientHgFlag')
        resolve()
        location.href='/'
      })
    },
    Unread({commit}){
      unread({}).then(r=>{
        if(r.data && r.data.length){
          commit('Unread1',true);
        }else{
          commit('Unread1',false);
        }
      })
    }
  }
}

export default user
