import request from '@/utils/request'

/** 发送短信验证码
 * @param {String} phone 电话号码
 * @param {String} verifCode 验证码类型 1-注册，2-登录，3-修改手机号原手机发送验证码，4-重置登录密码 5-重置交易密码 6-修改手机号新手机号发送验证码
 * @param {String} [uuid] UUID（验证码类型5,6必传）
 * @param {String} [oldPhone] 原手机号码（验证码类型6必传）
 */
export function sendMsgCode(phone,verifCode,uuid,oldPhone) {
  return request({
    url: '/api/sale/sms/routineSend',
    method: 'post',
    data: {
      phone,verifCode,uuid,oldPhone
    }
  })
}

/** 短信码校验
 * @param {String} phone 手机号码
 * @param {String} code 短信验证码
 * @param {String} verifCode 验证码类型 1-注册，2-登录，3-修改手机号，4-重置密码
 */
export function valiMsgCode(phone,code,verifCode) {
  return request({
    url: '/api/sale/sms/checkVerifCode',
    method: 'post',
    data: {
		phone,code,verifCode
	}
  })
}

/** 注册客户基础信息
 * @param {String} clientSource 客户来源 0:APP 1:WEB
 * @param {String} mobileTel 注册手机号
 * @param {String} password 登录密码
 * @param {String} passwordCopy 重复密码
 * @param {String} custType 客户类型 0:机构  1:个人  2:产品
 * @param {String} registerUUID 注册流程状态标志UUID
 * @param {String} channel 渠道来源（1.自主注册 2.老客户推荐 3.客户经理推荐 4.三方渠道）
 * @param {String} [invitorId] 邀请人ID（渠道来源为老客户推荐时不为空）
 * @param {String} [inviteCode] 推荐码（渠道来源为客户经理推荐时不为空）
 * @param {String} [popChannelId] 三方渠道ID （渠道来源为三方渠道注册时不为空）
 */
export function register(clientSource,mobileTel,password,passwordCopy,custType,registerUUID,channel,invitorId,inviteCode,popChannelId) {
  return request({
    url: '/api/sale/customer/register',
    method: 'post',
    data: {
		clientSource,mobileTel,password,passwordCopy,custType,registerUUID,channel,invitorId,inviteCode,popChannelId
	}
  })
}

/** 免密注册
 * @param {String} phone 手机号
 * @param {String} uuid 短信校验编号
 * @param {Object} channelData 渠道参数
 */
export function noPwdReg(phone,uuid,channelData) {
  return request({
    url: '/api/sale/customer/noPwdRegister',
    method: 'post',
    data: {
      mobileTel:phone,
      registerUUID:uuid,
      clientSource: '1',
      custType: '1',
      ...channelData
    }
  })
}

/** 推广渠道点击统计 及 访问统计
 * @param {Number} channelId 手机号
 * @param {Number} urlScanNum 访问量统计传 1
 * @param {Number} registerClickedNum 点击统计传 1
 */
export function channelCount(channelId,urlScanNum,registerClickedNum) {
  return request({
    url: '/api/system/pop/totalNum',
    method: 'post',
    data: {
      channelId,
      urlScanNum,
      registerClickedNum,
      channelPopUrl: location.href
    }
  })
}