/*
 * @Author: your name
 * @Date: 2021-09-26 16:26:40
 * @LastEditTime: 2021-09-26 16:29:47
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \zhfd-web-sale\src\main.js
 */
import Vue from 'vue'
import ElementUI, {
	MessageBox
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import router from './router'
import store from './store'
import Router from 'vue-router'

import 'font-awesome/css/font-awesome.min.css'

import './utils/NumberExtention.js'
import './utils/StringExtention.js'
import './utils/DateExtention.js'
import './static/css/com.css'
import './static/fonts/fonts.css'
import './static/iconfont/iconfont.css'
import '@/utils/filters'
import numFormat from '@/utils/numFormat.js';
import {
	getDicts
} from "@/api/getDicts.js";
import {
	iconfont
} from "@/static/iconfont/iconfont.js";
import Pagination from "@/components/Pagination";
import MyPlugin from './utils/minxins';
import isTradableNew from '@/utils/isTradableNew';

Vue.use(ElementUI);
Vue.use(MyPlugin);


Vue.component('Pagination', Pagination)

import zhRadio from '@/components/zhRadio'
Vue.component('ZhRadio', zhRadio)
import zhDialog from '@/components/zhDialog'
Vue.component('ZhDialog', zhDialog)
import zhStep from '@/components/zhStep'
Vue.component('ZhStep', zhStep)
Vue.component('iconfont', iconfont)

import VueAwesomeSwiper from 'vue-awesome-swiper'

// require styles
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default global options } */ )

import echarts from '@/utils/echarts.js'

Vue.prototype.$echarts = echarts
Vue.prototype.numFormat = numFormat;
Vue.config.productionTip = false
Vue.prototype.isTradableNew = isTradableNew;

Vue.prototype.getDicts = getDicts;
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'xjUd1qV5AO4e9xdhPcpYPWAzm6hhvmMW'
})

router.beforeEach((to, from, next) => {
	if (to.meta.acc) {
		// const token = this.$store.getters.token;
		const userInfo = JSON.parse(localStorage.getItem('customerInfo'))
		if ( !userInfo) {
			// this.$message.warning("请登录!");
			next({
				name: "Login"
			});
			return;
		}
		if (userInfo.acctFlag !== '1') {
			MessageBox.confirm('您的账号还没开户，请完成开户！', '实名认证', {
				confirmButtonText: '去开户',
				cancelButtonText: '稍后补充',
				type: 'warning'
			}).then(() => {
				next({
					name: 'OpenAccount'
				})
			}).catch(() => {})
		} else {
			next()
		}
	} else {
		next()
	}
	document.title = '众惠基金-专业的基金销售服务「一站式财富管理平台」'
	if (to.meta.title && to.meta.title!='首页') {
		document.title = to.meta.title + '-' + document.title
	}

})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')