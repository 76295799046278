import request from '@/utils/request'


// 查询议管理分页列表
export function protPage(data) {
  return request({
    url: '/api/sale/platfcfg/prot/page',
    method: 'POST',
    data:{
      ...data,
      status:1
    }
  })
}

/** 获取广告图像
 * @param {String} imgType 图像类型 0:静态图标 1:轮播图
 * @param {String} imgUseSite 图像使用位置，字典 sys_img_user_site 获取
 */
export function getImgs(imgType,imgUseSite) {
  return request({
    url: '/api/sale/platfcfg/img/listApp',
    method: 'POST',
    data:{
      imgType,imgUseSite,
      imgUsePlatform:'1',
      publishStatus: '1'
    }
  })
}