<template>
  <section class="footer">
    <div class="footer-content vw">
      <div class="footer-left">
        <router-link to="/" :target="isShareLogin?'_blank':'_self'"><img src="@/static/img/logo-2.png" alt="众惠基金" class="footer-logo"></router-link>
        <div class="footer-customer">
          <b class="ico-cutomer" />
          <b>客服热线: 400-839-1818</b>
          <p style="font-size:12px;">周一至周五9:00-22:00(法定节假日除外)</p>
        </div>
        <div class="footer-customer">
          <b class="ico-email" />
          <b>客服邮箱:</b>
          <p style="font-size: 1.2em;">business@hyzhfund.com</p>
        </div>
      </div>
      <div class="footer-right">
        <div v-if="!isShareLogin">
          <img src="@/static/img/app-crcode.png" alt="app下载" class="crcode">
          <p>众惠基金APP</p>
        </div>
        <div>
          <img src="@/static/img/wx-crcode.png" alt="关注公众号" class="crcode">
          <p>众惠基金公众号</p>
        </div>
      </div>
      <div class="footer-menu">
        <dl>
          <dt>关于众惠</dt>
          <dd>
            <router-link :to="{name:'AboutUs'}" :target="isShareLogin?'_blank':'_self'">众惠介绍</router-link>
            <router-link :to="{name:'Information'}" :target="isShareLogin?'_blank':'_self'">公司动态</router-link>
            <router-link :to="{name:'ContactUs'}" :target="isShareLogin?'_blank':'_self'">联系我们</router-link>
            <router-link :to="{name:'RecruitPage'}" :target="isShareLogin?'_blank':'_self'">加入我们</router-link>
            <router-link :to="{name:'CommProblem'}" :target="isShareLogin?'_blank':'_self'">常见问题</router-link>
          </dd>
        </dl>
        <dl>
          <dt>产品与服务</dt>
          <dd>
            <a @click="topage('PublicFund')">公募产品</a>
            <a @click="topage('PrivateFund')">高端理财</a>
            <a @click="topage('Hui')">惠金宝</a>
            <!-- <a>资管产品</a> -->
          </dd>
        </dl>
        <dl>
          <dt>信息披露</dt>
          <dd>
            <a @click="agree2&&agree2.length>0?toPdf(agree2):treatyerr()">投资者权益须知</a>
            <a @click="agree3&&agree3.length>0?toPdf(agree3):treatyerr()">隐私保护协议</a>
            <!-- <a @click="topdfs_()">隐私保护协议</a> -->

            <a @click="agree4&&agree4.length>0?toPdf(agree4):treatyerr()">风险提示函</a>
            <router-link :to="{name:'Honors'}" :target="isShareLogin?'_blank':'_self'">资质证明</router-link>
          </dd>
        </dl>
      </div>
    </div>
    <div class="vw bottom">
      <div>
        风险提示:
        <br>
        市场有风险，投资需谨慎。本平台提供的所有资料和信息仅供参考,并不构成任何投资意见与建议。众惠基金对使用本内容所引发的任何直接或间接损失不承担任何责任。基金的过往业绩并不预示其未来表现，基金管理人管理的其它基金的业绩并不构成基金表现的保证。众惠基金提醒投资者基金投资”买者自负“，在做出投资决策后，基金运营状况与基金净值变化引致的投资风险，由投资者自行负担。投资者应根据自身风险承受能力，审慎决定是否参与基金交易及相关业务。投资者认购（或申购）基金时应认真阅读《基金合同》、《基金招募说明书》和《产品资料概要》等法律文件。
      </div>
      <div style="text-align:center; margin-top:1em;">
        基金销售服务由众惠基金销售有限公司提供 | Copyright © 2021 众惠基金销售有限公司 版权所有 | 网站备案:
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">黔ICP备17000116号-4</a>
				|
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52011502001581"  target="_blank">
				<img src="@/static/img/beian-gongan.png" alt="公安备案" style="vertical-align:middle;">
          公安备案：52011502001581
        </a>
      </div>
    </div>
    <right-bar v-if="!isShareLogin" />
  </section>
</template>

<script>
  import {
    getDicts
  } from "@/api/dict.js";
  import {
    protPage
  } from "@/api/other/index.js";
  import RightBar from "./RightBar.vue";
  export default {
    name: "Footer",
    components: {
      RightBar
    },
    data() {
      return {
        agree2: "",
        agree3: "",
        agree4: ""
      };
    },
    computed: {
      isShareLogin() {
        return this.$route.name === "Login" && this.$route.query.isShare == "1";
      }
    },
    created() {
      getDicts("protocol_name").then(result => {
        let dicts = result.data || [];
        protPage({
          pageSize: 100
        }).then(result => {
          let data = result.data.data || [];

          let id2 =
            (dicts.find(m => m.dictLabel == "《投资者权益需知》") || {})
            .dictValue || "";
          this.agree2 = (data.find(m => m.protName === id2) || {}).protUrl;
          let id3 =
            (dicts.find(m => m.dictLabel == "《众惠基金隐私保护指引》") || {})
            .dictValue || "";
          this.agree3 = (data.find(m => m.protName === id3) || {}).protUrl;
          let id4 =
            (dicts.find(m => m.dictLabel == "《风险揭示书》") || {}).dictValue ||
            "";
          this.agree4 = (data.find(m => m.protName === id4) || {}).protUrl;
        });
      });
    },
    methods: {
      topage(n) {
        if (this.isShareLogin) {
          let news = this.$router.resolve({
            name: n
          }, "_blank");
          window.open(news.href, "_blank");
        } else {
          this.$router.push({
            name: n
          }, "_blank");
        }
      },
      topdfs_() {
        window.open("/privacy.html");
      }
    }
  };
</script>

<style scoped>
  .footer {
    font: 14px/1em "microsoft yahei";
    color: #fff;
    background: #25293d;
  }

  .footer-content {
    padding-top: 80px;
    background: #25293d;
  }

  .footer-left {
    float: left;
    width: 270px;
  }

  .footer-logo {
    margin-bottom: 1em;
    width: 246px;
    height: 66px;
  }

  .footer-customer {
    margin-top: 1em;
    line-height: 1.5em;
  }

  .footer-customer b {
    font-size: 16px;
    font-weight: normal;
    padding-left: 10px;
  }

  .footer-customer p {
    padding-left: 40px;
    font-size: 12px;
  }

  .footer-menu {
    display: flex;
    justify-content: space-evenly;
    margin: 0 320px 0 290px;
    min-height: 220px;
  }

  .footer-menu dt {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .footer-menu dd {
    line-height: 2.4em;
  }

  .footer-menu a {
    display: block;
    color: #fff;
  }

  .footer-right {
    float: right;
    width: 300px;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  .footer-right .crcode {
    padding: 10px;
    width: 120px;
    height: 120px;
    margin-bottom: 1em;
    background: #fff;
  }

  .bottom {
    border-top: 1px solid #ddd;
    background: #25293d;
    line-height: 1.5em;
    padding: 20px 0;
    color: #d4d4d4;
  }

  .bottom a {
    color: #fff;
  }
</style>