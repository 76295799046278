<template>
  <div
    v-if="isOpen"
    class="dialog-wrap"
  >
    <div
      class="dialog-content"
      :style="{
        width: width.slice(-1) === '%' ? width : width + 'px',
        height: height.slice(-1) === '%' ? height : height + 'px'
      }"
    >
      <h3>
        <span>{{ title }}</span>
        <i
          class="iconfont icon-form-idcard-error"
          @click="close"
        />
      </h3>
      <div class="dialog-main">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ZhDialog',
    props: {
      title: {
        type: String,
        default: '',
      },
      width: {
        type: String,
        default: '600'
      },
      height: {
        type: String,
        default: '680'
      }
    },
    data() {
      return {
        isOpen: false,
      }
    },
    methods: {
      close() {
        this.isOpen = false
      },
      open() {
        this.isOpen = true
      }
    },
  }
</script>

<style lang="less" scoped>
  .dialog-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    .dialog-content {
      background: #FFFFFF;
      padding-top: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &>h3 {
        padding: 0 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0;
        box-sizing: border-box;
        &>span {
          font-weight: bold;
          font-size: 18px;
          color: #1F1F1F;
        }
        .iconfont {
          color: #E0E2E8;
          font-size: 24px;
          font-weight: normal;
        }
      }
    }
    .dialog-main {
      width: 100%;
      flex: 1;
      overflow: hidden;
    }
  }
</style>