/** 基金超市 */
import Layout from "@/components/Layout.vue"
export const fundMarket = 
{
  path: '/fund-market',
  component: Layout,
  children: [{
      path: 'index',
      name: 'FundMarket',
      component: () => import('@/views/fund-market/Index.vue'),
      meta: {
        title: '基金超市'
      }
    },
    {
      path: 'detail/:fundCode',
      name: 'FundDetail',
      props: true,
      component: () => import('@/views/fund-market/FundDetail.vue'),
      meta: {
        title: '基金详情'
      }
    },
    {
      path: 'notice/:id',
      name: 'FundNotice',
      props: true,
      component: () => import('@/views/fund-market/Notice.vue'),
      meta: {
        title: '基金公告'
      }
    },
    {
      path: 'topicFund',
      component: Layout,
      children: [{
          path: 'index',
          name: 'TopicFund',
          component: () => import('../views/fund-market/TopicFund/Index.vue'),
          meta: {
            title: '主题基金'
          }
        },
        {
          path: 'list',
          name: 'TopicFunds',
          component: () => import('../views/fund-market/TopicFund/List.vue'),
          meta: {
            title: '全部主题基金'
          }
        }
      ]
    },
    {
      path: 'new-fund',
      component: Layout,
      children: [{
          path: 'index',
          name: 'NewFund',
          component: () => import('@/views/fund-market/new-fund/Index.vue'),
          meta: {
            title: '新发基金'
          }
        },
        {
          path: 'detail/:fundCode',
          props: true,
          name: 'NewFundDetail',
          component: () => import('@/views/fund-market/new-fund/FundDetail.vue'),
          meta: {
            title: '基金详情'
          }
        }
      ]
    },
    {
      path: 'fixedarea',
      component: Layout,
      children: [{
          path: 'index',
          name: 'Fixedarea',
          component: () => import('@/views/fund-market/fixedarea/Index.vue'),
          meta: {
            title: '定投专区'
          }
        },
        {
          path: 'list',
          name: 'Fixedareas',
          component: () => import('@/views/fund-market/fixedarea/List.vue'),
          meta: {
            title: '全部定投'
          }
        },
        {
          path: 'fixedCal',
          name: 'fixedCal',
          component: () => import('@/views/fund-market/fixedarea/fixedCal'),
          meta: {
            title: '定投计算'
          }
        }
      ]
    }
  ]
}