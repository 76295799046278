let MyPlugin={};

MyPlugin.install=Vue=>{
     // 注入组件
     Vue.mixin({
        data() {
            return {

            }
        },
        methods: {
            //跳转pdf预览
            toPdf(pdfUrl){
                // let str=pdfUrl.substring(pdfUrl.indexOf(':') + 1)
                // let strurl='https:'+str
              window.open(`/pdfs/web/viewer.html?file=${encodeURI(pdfUrl)}`, "_blank");
            },
            //跳转公告页面预览
            tofundnotice(id){
               let urls= this.$router.resolve({name:'FundNotice',params:{id:id}})
               window.open(urls.href,"_blank")
            },
            treatyerr(){
                this.$message.error('管理员暂未上传该协议！')
            }
        }
    })
}
export default MyPlugin
