/** 高端理财 */
import Layout from "@/components/Layout.vue"
export const highFinancial = 
{
  path: '/highFinancial',
  component: Layout,
  children: [
    {
      path: '',
      name: 'HighFinancial',
      component: () => import('../views/high-fm/HighFinancial.vue'),
      meta: {
        title: '高端理财',acc:true
      }
    },
    {
      path: 'detail/:fundCode',
      name: 'HighFinancialDetail',
      props: true,
      component: () => import('../views/high-fm/FundDetail.vue'),
      meta: {
        title: '高端理财',acc:true
      }
    },
    {
      path: 'notice/:id',
      name: 'PrivateNotice',
      props: true,
      component: () => import('../views/high-fm/Notice.vue'),
      meta: {
        title: '私募公告',acc:true
      }
    }
  ]
}