//
let f = (n,mark = true)=>{
	if( n && mark ){
		let num = Number(n).toFixed(2);
		num = num>0?`+${num}`:(num==0?'0.00':`${num}`);
		return num
	}else if( n && !mark ){
		let num = Number(n).toFixed(2);
		return num;
	}else{
		return '0.00'
	}
	// if(n){
	// 	let num = Number(n).toFixed(2);
	// 	num = num>0?`+${num}`:(num==0?'0.00':`${num}`);
	// 	return num
	// }else{
	// 	return '0.00'
	// }
}

export default f;