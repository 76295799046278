<template>
	<section class="home">    
    <div :class="['banner',{'banner-bg': showBannerBg}]">
      
        <div class="banner-in" v-if="banners.length > 0">
          <el-carousel height="550px">
            <el-carousel-item v-for="item in banners" :key="item.id">
              <a :href="item.href" :title="item.title">
                <img :src="item.src" :alt="item.title">
              </a>
            </el-carousel-item>
          </el-carousel>   
        </div>
        <div class="banner-vw vw flex-between">
          <div style="color: #fff;" v-if="showBannerBg">            
            <p style="font:52px/1.6em microsoft yahei;">Consulting&lnvestment</p>
            <p style="font:52px/1.6em microsoft yahei;">博采众长·惠泽天下</p>
            <p style="font:25px/1.6em microsoft yahei;  margin-bottom: 30px;">省时省心省力7x24小时实时交易</p>
            <router-link style="border:1px solid #fff; padding: 10px 30px; border-radius: 2em; background: none; color: #fff; font-size: 18px;" class="btn" :to="{name:'FundMarket'}">点击查看 →</router-link>
          </div>
          <div v-else></div>
          <login :isHome="true" class="login" />
        </div>
      
    </div>
    
		<div class="banner-nav vw flex-between">
			<img src="@/static/img/index/index-1_r1_c1.jpg" alt="持牌经营">
			<img src="@/static/img/index/index-1_r1_c3.jpg" alt="科技驱动">
			<img src="@/static/img/index/index-1_r1_c5.jpg" alt="风控护航">
			<img src="@/static/img/index/index-1_r1_c7.jpg" alt="服务保障">
		</div>
		<div class="jing-xuan">
      <div class="vw" style="background: none;">
        <div class="sec-title">
          <span class="ico iconfont icon-a-home-recommandlist orange"></span>
          <span class="" style="margin-left: 10px;">精选榜单</span>
          <p class="sec-title2"></p>
        </div>
        <div class="flex-between">
          <div class="nav">
            <a :class="{'current' : recommendTag_no === 1}" title="潜力指数" @click="getJingXuan(1)">
              <p class="name">{{recommendTag_1.tagName}}</p>
              <p class="desc">{{recommendTag_1.tagDescribe}}</p>
              <span class="more" @click="goJingXuan(5,1)">更多 &gt;</span>
            </a>
            <a :class="{'current' : recommendTag_no === 2}" title="业绩领先" @click="getJingXuan(2)">
              <p class="name">{{recommendTag_2.tagName}}</p>
              <p class="desc">{{recommendTag_2.tagDescribe}}</p>
              <span class="more" @click="goJingXuan(6,2)">更多 &gt;</span>
            </a>
            <a :class="{'current' : recommendTag_no === 3}" title="海外分红" @click="getJingXuan(3)">
              <p class="name">{{recommendTag_3.tagName}}</p>
              <p class="desc">{{recommendTag_3.tagDescribe}}</p>
              <span class="more" @click="goJingXuan(7,3)">更多 &gt;</span>
            </a>
          </div>
          <div class="fund-box">
            <router-link class="fund-sec" v-for="item in jingXuan" :key="item.fundCode" :to="{name:'FundDetail',params:{fundCode:item.fundCode ,fundType:item.ofundType}}">
              <div class="title">
                <span class="title-label">
                  <span class="ico-wang-guan orange"></span>
                  <span> 好基精选</span>
                </span>
                <span class="title-txt">{{ item.name }}</span>
                <sup class="orange"><b>{{ item.fundCode }}</b></sup>
              </div>
              <div class="flex-between">
                <div class="flex-between desc-box">
                  <div>
                    <p :class="['price',item.incomeYear >=0 ? 'red':'green']"><b>{{ item.priceRate }}%</b></p>
                    <p class="desc-txt gray">近一年涨幅</p>
                  </div>
                  <div>
                    <p class="price">{{ item.price }}</p>
                    <p class="desc-txt gray">单位净值({{item.priceDate}})</p>
                  </div>
                  <div>
                    <p class="price">{{ item.minBuy }}元</p>
                    <p class="desc-txt gray">起投</p>
                  </div>
                  <div v-if="false">
                    <div class="service-price" v-show="item.handleRate > 0">
                      <p class="orange">{{ item.handleRate}}%</p>
                      <p class="service-rate gray" v-show="item.handleDiscount>0">{{ item.handleDiscount }}折</p>
                      <s class="gray">{{ item.handleRateBase }}%</s>
                    </div>
                    <div class="no-service-price gray" v-show="!item.handleRate">
                      免手续费
                    </div>
                    <p class="desc-txt gray">手续费</p>
                  </div>
                </div>
                <div style="padding-top: 0.6em;">
                  <a :class="['btn btn-buy', {'disable stop-buy':item.buyStatus === '0'}]" @click.prevent="buyFund(item)">{{item.buyTxt}}</a>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
		<div class="fund-order vw">
			<div class="sec-title">
				<span class="ico ico-pai-hang orange"></span>
				<span class="title-txt">基金排行</span>
				<p class="sec-title2 gray"></p>
			</div>
			<div style="position: relative;">
				<div class="title-all btn" @click="toggleFundCat">
					<span>查看全部 </span>
					<span class="ico-mu-lu orange"></span>
				</div>
				<div :class="['flex-between fund-cat-sec',{'show-less':!showAllFundCat}]">
					<div :class="['fund-cat-all',{'current': !fundOrderCat}]" @click="getFundSort()">
						<p>全部</p>
						<span class="ico-sort-down orange"></span>
					</div>
					<div class="fund-cat-box flex-between">
						<div :class="{'current': fundOrderCat===item.code}" v-for="item in fundOrderCats" :key="item.code" @click="getFundSort(item.code)">
							<p>{{ item.name }}</p>
							<span class="ico-sort-down orange"></span>
						</div>
					</div>
				</div>
				<div class="flex-between orange fund-order-title fund-order-list">
					<span>基金代码</span>
					<span class="name">基金名称</span>
					<span>风险等级</span>
					<span class="flex-between">
						<span>最新净值</span>
						<i :class="['btn', fundSortStyle('newestValue')]" @click="sortFund('newestValue')"></i>
					</span>
					<span class="flex-between">
						<span>日涨跌幅</span>
						<i :class="['btn', fundSortStyle('changeDay')]" @click="sortFund('changeDay')"></i>
					</span>
					<span class="flex-between">
						<span>
							近一个月<br>涨跌幅 
						</span>
						<i :class="['btn', fundSortStyle('changeMonth')]" @click="sortFund('changeMonth')"></i>
					</span>
					<span class="flex-between">
						<span>
							近三个月<br>涨跌幅 
						</span>
						<i :class="['btn', fundSortStyle('changeThreeMonth')]" @click="sortFund('changeThreeMonth')"></i>
					</span>
					<span class="flex-between">
						<span>
							近半年<br>涨跌幅 
						</span>
						<i :class="['btn', fundSortStyle('changeHalfYear')]" @click="sortFund('changeHalfYear')"></i>
					</span>
					<span class="flex-between">
						<span>
							近一年<br>涨跌幅 
						</span>
						<i :class="['btn', fundSortStyle('changeYear')]" @click="sortFund('changeYear')"></i>
					</span>
					<span v-if="false">买入费率</span>
					<span class="action">操作</span>
				</div>
				<div class="flex-between dark fund-order-content fund-order-list" v-for="item in fundOrderData" :key="item.fundCode">
					<span class="">{{ item.fundCode }}</span>
          <router-link class="name" :to="{name:'FundDetail',params:{fundCode:item.fundCode ,fundType:item.ofundType}}">{{ item.fundName}}</router-link>
					<span :class="item.ofundRisklevel >= 3 ? 'red' : item.ofundRisklevel >= 1 ? 'orange' : 'gray'">{{ item.risklevel }}</span>
					<span class="value dark">
						<span>{{ item.newestValue }}</span>
						<br>
						<span class="gray">{{ item.enddate }}</span>
					</span>
					<span :class="formatRiseStyle(item.changeDay)">{{ item.changeDay }}%</span>
					<span :class="formatRiseStyle(item.changeMonth)">{{ item.changeMonth }}%</span>
					<span :class="formatRiseStyle(item.changeThreeMonth)">{{ item.changeThreeMonth }}%</span>
					<span :class="formatRiseStyle(item.changeHalfYear)">{{ item.changeHalfYear }}%</span>
					<span :class="formatRiseStyle(item.changeYear)">{{ item.changeYear }}%</span>
					<span :class="item.saleFeeRate.indexOf('%') > -1 ? 'orange' : 'gray'" v-if="false">{{ item.saleFeeRate}}</span>
					<span class="action">
						<input type="button" :value="item.buyTxt" :class="['btn btn-buy btn-orange', {'disable stop-buy':item.buyStatus === '0'}]" @click="buyFund(item)">
					</span>
				</div>
			</div>
		</div>
    <div class="company-news">
		<div class="vw">
			<div class="sec-title">
				<span class="ico ico-news orange"></span>
				<span class="title-txt">公司动态</span>
				<p class="sec-title2 gray"></p>
			</div>
			<div class="news-contents flex-between">
				<div class="news-left">
					<img :src="top1News.webCoverUrl" :alt="top1News.title">
					<p class="title">{{ top1News.title }}</p>
					<p class="flex-between small gray">
						<span>{{ (top1News.updateTime||'').substr(0,10) }}</span>
						<span>阅读量: {{ top1News.readCount }}</span>
					</p>
					<p class="summary small">
						{{top1News.summary}}
					</p>
          <router-link class="btn btn-orange-plain view" :to="{name:'Detail',query:{infoId:top1News.infoId}}" v-if="top1News.configType === '0'">全文 ></router-link>
					<a class="btn btn-orange-plain view" :href="top1News.linkedUrl" target="_blank" v-else>全文 ></a>
				</div>
				<div class="news-right">
					<div class="flex-between cat">
						<span class="cat orange">热点资讯</span>
						<router-link class="small gray more btn" :to="{name:'Information'}">更多></router-link>
					</div>
					<a class="news-sec flex-between" v-for="item in hotNews" :key="item.infoId" :href="item.configType === '1' ? item.linkedUrl : `#/detail?infoId=${item.infoId}`" target="_blank">
						<img :src="item.webCoverUrl" :alt="item.title">
						<div class="news-content">
							<p class="title">
								{{ item.title }}
							</p>
							<p class="small gray summary">
								{{ item.summary }}
							</p>
						</div>
					</a>
				</div>
				<div class="news-center">
					<div class="cat orange flex-between">
						<span>平台公告</span>
						<a class="small gray more btn" v-if="false">更多></a>
					</div>
          <div class="block">
            <div v-for="item in notices" :key="item.id">
              <router-link class="title" :to="{name:'NewsDetail',params:{id:item.id}}">{{ item.noticeTitle }}</router-link>
            	<div class="flex-between gray small">
            		<span>{{ (item.custDisplayTime||'').substr(0,10) }}</span>
            		<span>阅读量: {{ item.readingVolume }}</span>
            	</div>
            </div>
          </div>					
					<div class="cat orange flex-between" style="margin-top: 36px;">
						<span>基金学堂</span>
						<router-link class="small gray more btn" :to="{name:'FundSchool'}">更多></router-link>
					</div>
          <div class="block">
            <div v-for="item in eduNews" :key="item.infoId">
            	<a class="title" :href="item.configType === '1' ? item.linkedUrl : `#/detail?infoId=${item.infoId}`">{{ item.title }}</a>
            	<div class="flex-between gray small">
            		<span>{{ (item.updateTime||'').substr(0,10) }}</span>
            		<span>阅读量: {{ item.readCount }}</span>
            	</div>
            </div>
          </div>
				</div>
			</div>
		</div>
		</div>
    <div class="certificate">
			<div class="bbg">&nbsp;</div>
			<div class="bbc vw">
				<div class="title orange">
					<p>
						— 资质认证 —
					</p>
				</div>
				<div class="content flex-between">
					<div class="cert-sec">
						<img src="@/static/img/index/index-4_r1_c1.jpg" alt="基金销售资格证">
						<p>基金销售资格证</p>
					</div>
					<div class="cert-sec">
						<img src="@/static/img/index/index-4_r1_c3.jpg" alt="监督机构">
						<p>
							监督机构
							<br>
							中国证监会
						</p>
					</div>
					<div class="cert-sec">
						<img src="@/static/img/index/index-4_r1_c5.jpg" alt="监管银行">
						<p>
							监管银行
							<br>
							中国民生银行
						</p>
					</div>
					<div class="cert-sec">
						<img src="@/static/img/index/index-4_r1_c7.jpg" alt="自律组织">
						<p>
							自律组织
							<br>
							中国证券投资基金业协会
						</p>
					</div>
				</div>
			</div>
		</div>
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
	</section>
</template>

<script>
import Login from '@/views/reg/components/Login.vue'
import DinDetails from '@/components/Models.vue'
import {getDicts} from '@/api/dict.js'
import {fundList} from '@/api/fund.js'
import {topList,sysNews} from '@/api/news.js'
import {getChannalAndTag} from '@/api/achievementLead.js'
import {getImgs} from '@/api/other/index.js'
export default {
	name: 'Home',
	components: {
    Login,DinDetails
  },
	data() {
		return {
      recommendTag_no: 1,
      recommendTag_1:{
        tagName: '潜力指数榜单',
        tagDescribe: '潜力指数榜单TOP'
      },
      recommendTag_2:{
        tagName: '业绩领先榜单',
        tagDescribe: '业绩领先榜单'
      },
      recommendTag_3:{
        tagName: '海外分红榜单',
        tagDescribe: '海外分红榜单',
      },
			jingXuan: [],
			showAllFundCat: false,
			fundOrderCats: [],
			fundOrderCat: '',
			fundOrderType: {},
			fundOrderData: [],
			riskLevel: [],
			top1News:{},
			hotNews:[],
			eduNews:[],
			notices: [],
      
      banners:[],
      showBannerBg: false,
      
      showBuyConfirm: false,
      buyConfirmTxt: '',
      buyConfirmStep: 0,
      preBuyFund:null
		}
	},
	created() {
    this.getBanners()
    this.getChannalAndTag()
		this.getJingXuan(1)
		this.getFundType()
		this.getRiskLevel().then(() => {
			this.sortFund('changeDay')
		})
		this.topList()
		this.sysNews()
	},
	methods: {
    /** 获取banner */
    getBanners() {
      getDicts('sys_img_user_site').then(result => {
        let imgUseSite = (result.data.find(m => m.dictLabel === 'web首页轮播')||{}).dictValue
        if(imgUseSite) {
          getImgs('1',imgUseSite).then(imgResult => {
            this.banners = Array.from(imgResult.data,m => {
              return {
                title:m.imgName,
                href:m.imgLinkedUrl,
                src:m.imgUrl,
                id:m.imgId
              }
            })
            this.showBannerBg = this.banners.length === 0
          }).catch(() => {
            this.showBannerBg = true
          })
        } else {
          this.showBannerBg = true
        }
      })
    },
    /** 获取精选基金标签 */
    getChannalAndTag() {
      getChannalAndTag().then(result => {
        let data = result.data.filter(m => m.recommendChannel === '2')
        if(data.length > 0) {
          this.recommendTag_1 = data[0]
        }
        if(data.length > 1) {
          this.recommendTag_2 = data[1]
        }
        if(data.length > 2) {
          this.recommendTag_3 = data[2]
        }
      })
    },
		/** 更多精选基金跳转
		 * @param {Number} type 类型 1:潜力,2:业绩,3:海外
		 */
		goJingXuan(tagCode,type) {
			this.$router.push({
        name:'AchievementLead',
        query:{
          tagCode,
          type
        }
      })
		},
		/** 精选基金
		 * @param {Number} type 类型 1:潜力,2:业绩,3:海外
		 */
		getJingXuan(type) {
      this.recommendTag_no = type
			let params = {
				pageSize:3,
				pageNum:1,
				privateFlag: '0'
			}
			if(type === 1) {
				params.customerWebOneTag = '1'
				params.customerWebOneTopTag = '1'
			}
			if(type === 2) {
				params.customerWebTwoTag = '1'
				params.customerWebTwoTopTag = '1'
			}
			if(type === 3) {
				params.customerWebThreeTag = '1'
				params.customerWebThreeTopTag = '1'
			}
			fundList(params).then(result => {
				let data = result.data.data.map(m => {
					let handleRate = parseFloat(m.saleFeeRate||0)
					let handleRateBase = parseFloat(m.saleFeeRateb||1)
					let handleDiscount = (handleRate / handleRateBase * 10).toFixed(1)
          let incomeYear = parseFloat(m.incomeYear||0)
					return {
						name: m.fundName,
						fundCode: m.fundCode,
						priceRate: (incomeYear > 0 ? '+' : '')+incomeYear.$trimZero(2),
            incomeYear: incomeYear,
						price: m.newestValue,
						priceDate: m.enddate,
						minBuy: (parseFloat(m.startInvestAmt||0) || 1).$formatBig(),
						handleRate: m.saleFeeRate,
						handleRateBase: m.saleFeeRateb,
						handleDiscount: parseFloat(handleDiscount),
            fundStatus: m.fundStatus,
            buyTxt: m.fundStatus === '1' ? '认购' : '买入',
            newfund: m.fundStatus === '1' ? '1' : '',
            ofundType: m.ofundType,
            buyStatus: m.buyStatus
					}
				})
				this.jingXuan = data
			})
		},
		/** 购买基金 */
		buyFund(fund) {
      if(fund.buyStatus === '0') {
        return;
      }
      this.buyConfirmStep = 0
      let token = this.$store.getters.token;
      let _userInfo = localStorage.getItem("customerInfo");
      if (!token || !_userInfo) {
        this.buyConfirmTxt = '请登录!'
        this.buyConfirmStep = 1
      }else {
        let userInfo = JSON.parse(_userInfo);
        if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
          this.buyConfirmTxt = '请先开户!'
          this.buyConfirmStep = 2
        }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
          this.buyConfirmTxt = '请补充资料!'
          this.buyConfirmStep = 3
        }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
          this.buyConfirmTxt = '请设置交易密码!'
          this.buyConfirmStep = 4
        }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
          this.buyConfirmTxt = '请先完成风险测评!'
          this.buyConfirmStep = 5
        }else if(userInfo.riskLevel < fund.ofundRisklevel){
          this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
          this.buyConfirmStep = 6
        }
      }
      this.preBuyFund = fund
      if(!this.buyConfirmStep) {
        this.buyConfirm()
        return
      }      
      this.showBuyConfirm = true
		},
    /** 购买基金确认 */
    buyConfirm() {
      this.showBuyConfirm = false
      if(this.buyConfirmStep === 1) { 
        this.$router.push({ name: "Login" })
        return
      }
      if(this.buyConfirmStep === 2) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 3) { 
        this.$router.push({ name: "PersonalDataSupply" });
        return
      }
      if(this.buyConfirmStep === 4) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 5) { 
        this.$router.push({ name: "RiskAssessment" });
        return
      }
      this.$router.push({name:'ProcessPurchase',params:{fundCode:this.preBuyFund.fundCode,newfund:this.preBuyFund.newfund}})
    },
		/** 获取基金类型 */
		getFundType() {
			getDicts('sys_fund_type').then(result => {
				if(!result.data) {
					return
				}
				this.fundOrderCats = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
		/** 获取基金风险级别 */
		getRiskLevel() {
			return getDicts('sys_fund_risklevel').then(result => {
				if(!result.data) {
					return
				}
				this.riskLevel = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
		/** 基金类型查看 */
		toggleFundCat() {
			this.showAllFundCat = !this.showAllFundCat
		},
		/** 基金排序箭头样式 */
		fundSortStyle(key) {
			return this.fundOrderType[key] === '-1' ? 'ico-sort-down' : this.fundOrderType[key] === '1' ? 'ico-sort-up' : 'ico-sort'
		},
		/** 基金排序 */
		sortFund(key) {
			let order = this.fundOrderType[key]
			order = !order ? '-1' : order === '-1' ? '1' : ''
			this.fundOrderType = {}
			this.fundOrderType[key] = order
			this.getFundSort(this.fundOrderCat)
		},
		/** 获取基金排行 */
		getFundSort(cat) {
			this.fundOrderCat = cat
			let params = {
				pageSize:5,
				pageNum:1,
				privateFlag: '0'
			}
			params.ofundType = cat
			let _params = {...params,...this.fundOrderType}
			fundList(_params).then(result => {
				let data = result.data.data
				data.forEach(m => {
					m.risklevel = ((this.riskLevel.find(n => m.ofundRisklevel === n.code)||{}).name||'').replace('风险','')
					m.ofundRisklevel = parseInt(m.ofundRisklevel||0)
					m.newestValue = m.newestValue.$trimZero()
					m.changeDay = (parseFloat(m.changeDay)>0?'+':'')+m.changeDay.$trimZero(2)
					m.changeMonth = (parseFloat(m.changeMonth)>0?'+':'')+m.changeMonth.$trimZero(2)
					m.changeThreeMonth = (parseFloat(m.changeThreeMonth)>0?'+':'')+m.changeThreeMonth.$trimZero(2)
					m.changeHalfYear = (parseFloat(m.changeHalfYear)>0?'+':'')+m.changeHalfYear.$trimZero(2)
					m.changeYear = (parseFloat(m.changeYear)>0?'+':'')+m.changeYear.$trimZero(2)
					m.saleFeeRate = m.saleFeeRate ? m.saleFeeRate + '%' : '免手续费'
          m.buyTxt = m.fundStatus === '1' ? '认购' : '买入'
          m.newfund = m.fundStatus === '1' ? '1' : ''
				})
				this.fundOrderData = data
			})
		},
		/** 格式化基金涨跌样式
		 * @param {Number|String} priceRate 涨跌幅
		 */
		formatRiseStyle(priceRate,gray) {
			priceRate = priceRate || 0
			priceRate = parseFloat(priceRate)
			return priceRate > 0 ? 'red' : priceRate < 0 ? 'green' : gray
		},
		/** 获取置顶资讯 */
		topList() {
			topList('0').then(result => {
				if(!result.data) {
					return
				}
				this.top1News = result.data.length > 0 ? result.data[0] : {}
				this.hotNews = result.data.slice(1,4)
			})
			topList('1').then(result => {
				if(!result.data) {
					return
				}
				this.eduNews = result.data
			})
		},
		/** 获取公告列表 */
		sysNews() {
			sysNews(3,1,'1').then(result => {
				if(!result.data) {
					return
				}
				this.notices = result.data.data
			})
		}
	}
};
</script>

<style lang="less" scoped>
.red{ color:#df0003;}
.green{ color:#00a100;}
.orange{ color:#deb87b;}
.gray{ color:#b3b3b3;}
.btn-orange { background: #deb87b; color:#fff; border-radius: 6px;}
.btn-orange-plain{ border: 1px solid #deb87b; background: #fff; color: #deb87b; border-radius: 6px;}

.home{ top:-90px; position: relative;}

.banner{ height: 550px; position: relative; overflow: hidden;}
.banner-in {
  img{ display: block; margin:auto;}
}
.banner-bg{background: #102131 url(../static/img/banner/home%20banner.jpg) center no-repeat;}
.banner-vw{ position: relative; z-index: 2; top:90px;  height:460px; align-items: center; background: none; }

.banner-nav{ padding: 2em 0;
	img{ width: 280px; height: 190px;}
}
.login{margin-top: -1100px;}

.jing-xuan{ background: #f3f4f6; padding: 20px 0;}
.sec-title{ font:bold 35px "microsoft yahei"; vertical-align: middle;
	.ico{ vertical-align: bottom; font-size:35px;}
	.title-txt{ margin-left: 10px;}
}
.sec-title2{ font: 14px "microsoft yahei";padding:10px 0 20px 3.3em;}
.jing-xuan{
	.nav{ width:280px;
		a{ width: 100%; height:130px; cursor: pointer; display: block;background: url(../static/img/index/index-2_r3_c1.png) no-repeat center bottom; margin-bottom: 2px; color:#8890a5;}
    a:first-child{ background-image: url(../static/img/index/index-2_r1_c1.png);}
    a:last-child{ background-image: url(../static/img/index/index-2_r5_c1.png);}
    a.current{ background-position: center top; color:#cfa26b;}
    .name{padding-left: 19px; padding-top: 17px; font-size: 24px;}
    .desc{padding-top: 3px; padding-left: 19px;}
    .more{padding-top: 15px; padding-left: 20px; font-size: 12px; display: inline-block;}
	}
	.fund-box{ width: 890px; background: #fff;}
	.fund-sec{box-shadow: 0px 15px 15px -15px #eee; padding: 13.6px 30px; display: block;}
	.title-label{ background: #BE8B5B; background-image: linear-gradient(45deg, black, transparent); color: #fff; border-radius: 1em; padding: 6px 10px;}
	.title-txt{font-size: 20px; margin:0 0.6em;}
	.price{ font-size:16px; }
	.service-rate{ background: #de0606; color:#fff;}
	.btn-buy{ height: 35px; line-height: 34px; border: 1px solid #ccc; color:#868686; width: 146px; border-radius: 3px; background: #fff; display: block; text-align: center;
    &:hover{ background: #F4D3AF; color:#D2A16C; border-color: #fff;}
  }
	.desc-box{ width: 75%; margin-top: 1.5em; line-height: 2em; text-align: center;}
	.service-price{ position: relative; line-height: 1.1em;}
	.no-service-price{ font-size: 1.2em; color:#999;}
	.service-rate{ position: absolute; line-height: 1em; padding: 2px 5px; right: -35px; top: -5px; }
}

.fund-order{ padding: 20px 0;
	.title-all{ position: absolute; right: 1px; top:-50px; font:bold 16px "microsoft yahei";}
	.fund-cat-box{ width: 1050px; flex-wrap: wrap; justify-content: flex-start;}
	.fund-cat-all,
	.fund-cat-box>div{ border:1px solid #ccc; color: #666; width: 120px; padding: 5px 0; text-align: center; position: relative; height: 1.5em; cursor: pointer; user-select: none;}
	.fund-cat-box>div { margin: 0 0em 1em 2em; }
	.show-less{ height:3em;}
	.fund-cat-sec {overflow: hidden;
		.ico-sort-down{ position: absolute; bottom: -7px; left:45%; display: none;}
		div.current{ border-color: #deb87b; background: #deb87b; color: #fff;}
		.current .ico-sort-down{ display: block;}
	}
	.fund-order-list{ text-align: center;}
	.fund-order-title{ background: #fff5ea; padding: 1.3em 0; margin-top: 2em; align-items: center;}
	.fund-order-title .btn{ font-size: 1.2em; margin: 0px 0 0 2px;}
	.fund-order-title>span{ justify-content: center; align-items: center;}
	.fund-order-list>span{ width: 7em;}
	.fund-order-list .name{ text-align: left; width:200px;}
	.fund-order-list .action {width:170px;}
	.fund-order-content { padding: 1.6em 0;  line-height: 2em; border-bottom: 1px solid #eee;
		.value{ line-height: 1.5em;}
		.dark{ color: #5d5d5d;}
		.btn-buy{ padding: 10px 44px; height:2.5em;}
	}
}
.company-news{ padding: 30px 0 40px 0; background: url(../static/img/banner/company-news%20bg.jpg) no-repeat center #2E251B;
  .vw{ background: none;}
  .sec-title{ color:#fff;}
	.news-contents{ padding:40px 20px;background: rgb(243 244 246 / 90%); 
		.cat{ font:bold 22px "microsoft yahei";align-items: baseline;}
		.small{ font-size: 12px;}
		.more{ padding-right: 0.5em;}
		.news-left{width:330px;		
			img{ width: 330px; height: 200px;}
			.title{font:bold 18px "microsoft yahei"; margin: 1em 0 0.5em 0;}
			.summary{ color: #686868; margin-top:15px; line-height: 1.8em;max-height: 5em; overflow: hidden;}
			.view{ border-radius: 2em; padding: 8px; margin-top: 30px; display: block; width:108px; text-align: center;}
		}
		.news-center{width:310px;
			.title{ line-height: 2.3em; height: 2.3em; overflow: hidden; font-size: 1.1em; margin-top: 7px; display: block;}
      .block{height: 175px; overflow: hidden;}
		}
		.news-right{width:450px;
			img{width:165px; height: 115px;}
			.news-sec{ margin-top: 25px; }
			.news-content{ padding:0; width:265px;}
			.summary{ max-height: 50px; overflow: hidden; margin-top: 14px; color: #666; line-height: 1.5em;}
			.title{ font: normal 16px "microsoft yahei"; max-height: 3em; overflow: hidden; }
		}
	}
}

.certificate { padding-bottom: 30px;
	.bbg{ background: #c69561; height: 200px;}
	.bbc{ background: #fff; margin-top: -150px;}
	.title{ padding: 30px; padding-bottom: 0; line-height: 30px; text-align: center; font:bold 30px microsoft yahei;}
	.title small{ font-size: 12px;}
	.content{ justify-content: center;}
	.cert-sec{ margin: 30px;.gray();}
	img{ box-shadow: 0 0 8px #eee; padding: 14px; width: 135px; height: 105px;}
	.cert-sec p{ line-height: 2em; text-align: center; margin-top: 1em;}
}
</style>
