import request from '@/utils/request'

/** 查询首页置顶的资讯列表
 * @param {String} infoType  0:热点资讯 1:基金学堂 2:基金学堂模块A 3:基金学堂模块B 4:基金学堂模块C
 */
export function topList(infoType) {
  return request({
    url: '/api/sale/platfcfg/info/topList',
    method: 'post',
	data: {
		infoType
	}
  })
}

/** 查询运维管理系统公告或者平台消息
 * @param {Number} pageSize 页值
 * @param {String} pageNum 页码
 * @param {String} noticeType 公告类型(1.系统公告 2.平台消息)
 */
export function sysNews(pageSize,pageNum,noticeType) {
  return request({
    url: '/api/system/notice/page',
    method: 'post',
    data: {
      pageSize,pageNum,noticeType,
      releaseStatus:1
    }
  })
}

/** 公告详情
 * @param {String} id 编号
 */
export function newsDetail(id) {
  return request({
    url: '/api/system/notice/detail',
    method: 'post',
    data: {
      id
    }
  })
}