import request from '@/utils/request'

// 查询惠金宝余额
export function superAcct(data) {
    return request({
      url: '/api/sale/user/bank/superAcct',
      method: 'post',
      data
    })
}
// 查询是否确认合格投资者
export function confirmInfo(data) {
  return request({
    url: '/api/system/quaIvstr/confirmInfo',
    method: 'POST',
    data
  })
}
// 查询用户名下银行卡
export function list(data) {
    return request({
      url: '/api/sale/user/bank/list',
      method: 'post',
      data
    })
}
// 查询用户现金宝可用余额
export function queryUserIncomeDetail(data) {
    return request({
      url: '/api/sale/trade/income/queryUserIncomeDetail',
      method: 'post',
      data
    })
}

//查询当前基金交易费率
export function rateMagMatch(data) {
    return request({
     url: '/api/system/rateMag/match',
     method: 'POST',
     data
    })
}

//公募申购
export function subscribe(data) {
    return request({
      url: '/api/sale/trade/subscribe/subscribe',
      method: 'POST',
   data
    })
  }

//公募认购
export function subscription(data) {
    return request({
      url: '/api/sale/trade/subscribe/subscription',
      method: 'POST',
   data
    })
}



//查询线下银行卡转账账户
export function getOfflineBankAcct(data) {
    return request({
      url: '/api/sale/account/getOfflineBankAcct',
      method: 'POST',
   data
    })
}

//定投申请
export function fixallotTrade(data) {
    return request({
      url: '/api/sale/fixallot/fixallotTrade',
      method: 'POST',
   data
    })
}


//私募订单确认
export function apply(data) {
    return request({
      url: '/api/system/sale/orderPrivate/apply',
      method: 'POST',
   data
    })
}

//查询用户名下可申购银行卡
export function findTradeBankAcct(data) {
    return request({
      url: '/api/sale/account/findTradeBankAcct',
      method: 'POST',
   data
    })
}
//查询用户名下可申购银行卡
export function findXjbBalance(data) {
  return request({
    url: '/api/sale/trade/xjb/findXjbBalance',
    method: 'POST',
 data
  })
}

/** 获取老用户银行卡 */
export function getOldBankList() {
  return request({
    url: '/api/sale/user/bank/getOldBankList',
    method: 'POST'
  })
}

//惠金宝申购
export function buyProd(data) {
    return request({
      url: '/api/sale/trade/xjb/buyProd',
      method: 'POST',
      data
    })
  }

// 惠金宝认购接口
export function subscriptionProd(data) {
	return request({
		url: '/api/sale/trade/xjb/subscriptionProd',
		method: 'POST',
		data
	})
}