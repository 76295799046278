/** 基金买卖 */
import Layout from "@/components/Layout.vue"
export const dealRecord = 
{
  path: "/deal-record",
  name: "",
  component: Layout,
  children: [{
      path: 'processpurchase',
      name: 'ProcessPurchase',
      component: () => import('@/views/transaction-rocess/ProcessPurchase.vue'),
      meta: {
        title: '交易流程-买入'
      }
    },
    {
      path: 'processpassword',
      name: 'ProcessPassword',
      component: () => import('@/views/transaction-rocess/ProcessPassword.vue'),
      meta: {
        title: '交易流程-买入-输入密码'
      }
    },
    {
      path: 'processoffline',
      name: 'ProcessOffline',
      component: () => import('@/views/transaction-rocess/ProcessOffline.vue'),
      meta: {
        title: '交易流程-买入-核对线下转账'
      }
    },
    {
      path: 'processofflinefour',
      name: 'ProcessOfflinefour',
      component: () => import('@/views/transaction-rocess/ProcessOfflinefour.vue'),
      meta: {
        title: '交易流程-买入-核对交易成功'
      }
    },
    {
      path: 'processsellout',
      name: 'ProcessSellOut',
      component: () => import('@/views/transaction-rocess/ProcessSellOut.vue'),
      meta: {
        title: '交易流程-卖出-份额'
      }
    },
    {
      path: 'p-selloutpassword',
      name: 'ProcessSellOutPassword',
      component: () => import('@/views/transaction-rocess/ProcessSellOutPassword.vue'),
      meta: {
        title: '交易流程-卖出-输入密码'
      }
    },
    {
      path: 'p-selloutfour',
      name: 'ProcessSellOutfour',
      component: () => import('@/views/transaction-rocess/ProcessSellOutfour.vue'),
      meta: {
        title: '交易流程-卖出-交易成功'
      }
    },
    {
      path: 'p-addprocess',
      name: 'AddProcess',
      component: () => import('@/views/transaction-rocess/AddProcess.vue'),
      meta: {
        title: '交易流程-买入-定投'
      }
    },
    {
      path: 'p-addproassword',
      name: 'AddProPassword',
      component: () => import('@/views/transaction-rocess/AddProPassword.vue'),
      meta: {
        title: '交易流程-买入-输入密码'
      }
    },
    {
      path: 'p-addtruefour',
      name: 'AddTrueFour',
      component: () => import('@/views/transaction-rocess/AddTrueFour.vue'),
      meta: {
        title: '交易流程-买入-操作成功'
      }
    },
    {
      path: 'p-processFundfour',
      name: 'ProcessFundfour',
      component: () => import('@/views/transaction-rocess/ProcessFundfour.vue'),
      meta: {
        title: '交易流程-买入-操作成功'
      }
    }

  ]
}