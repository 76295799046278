import request from '@/utils/request'

// 精选榜单
export function achievementLead(data) {

  return request({
    url: '/api/sale/fundCommon/page',
    method: 'post',
    data
  })
}

// 查询推荐渠道及渠道下标签
export function getChannalAndTag(data) {
  return request({
    url: '/api/system/prodManage/channalAndTag',
    method: 'POST',
    data
  })
}