/** 我的众惠 */
import Layout from "@/components/Layout.vue"
export const myFavors = {
  path: '/myFavors',
  name: 'MyFavors',
  component: () => import('@/views/MyFavors.vue'),
  redirect: {
    name: 'MyFundIndex'
  },
  meta: {
    title: '我的众惠'
  },
  children: [{
      path: 'invitation',
      name: 'invitation',
      component: () => import('@/views/other/invitation.vue'),
      meta: {
        title: '邀请好友',
        type: 1
      }
    },
    {
      path: 'agreement',
      name: 'agreement',
      component: () => import('@/views/other/agreement.vue'),
      meta: {
        title: '相关协议',
        type: 1
      }
    },
    {
      path: 'my-asset',
      component: Layout,
      meta: {
        title: "我的资产"
      },
      redirect: {
        name: 'MyFundIndex'
      },
      children: [

        {
          path: 'hui',
          name: 'Hui',
          component: () => import('@/views/my/MyFund/Hui.vue'),
          meta: {
            title: '惠金宝',
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },

        {
          path: 'myFundIndex',
          name: 'MyFundIndex',
          component: () => import('@/views/my/MyFund/MyFundIndex.vue'),
          meta: {
            title: '我的资产首页',
            type: 2,
            name: 'MyFundIndex',
            titles: 'MyFundIndex',
            acc: true // 是否需要拦截提示去开户
          }
        },
        {
          path: 'rechargeS1',
          name: 'RechargeS1',
          component: () => import('@/views/my/MyFund/RechargeS1.vue'),
          meta: {
            title: '充值',
            chdr: 1,
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'rechargeS2',
          name: 'RechargeS2',
          component: () => import('@/views/my/MyFund/RechargeS2.vue'),
          meta: {
            title: '充值',
            chdr: 1,
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'rechargeS3',
          name: 'RechargeS3',
          component: () => import('@/views/my/MyFund/RechargeS3.vue'),
          meta: {
            title: '充值',
            chdr: 1,
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'getMoney',
          name: 'GetMoney',
          component: () => import('@/views/my/MyFund/GetMoney.vue'),
          meta: {
            title: '提现',
            chdr: 1,
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'getMoney2',
          name: 'GetMoney2',
          component: () => import('@/views/my/MyFund/GetMoney2.vue'),
          meta: {
            title: '提现',
            chdr: 1,
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'getMoneyS3',
          name: 'GetMoneyS3',
          component: () => import('@/views/my/MyFund/GetMoneyS3.vue'),
          meta: {
            title: '提现',
            chdr: 1,
            type: 2,
            name: 'Hui',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'publicfund',
          name: 'PublicFund',
          component: () => import('@/views/my/publicfund/PublicFund.vue'),
          meta: {
            title: '公募基金',
            type: 2,
            name: 'PublicFund',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'asset-manage',
          name: 'AssetMange',
          component: () => import('@/views/my/assetManage/Index.vue'),
          meta: {
            title: '资管计划',
            type: 1,
            name: 'AssetMange',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'publicfundprocess',
          name: 'PublicFundProcess',
          component: () => import('@/views/my/publicfund/PublicFundProcess.vue'),
          meta: {
            title: '选择转换基金',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'publicfundprocesstwo',
          name: 'PublicFundProcesstwo',
          component: () => import('@/views/my/publicfund/PublicFundProcesstwo.vue'),
          meta: {
            title: '填写转换信息',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'publicfundprocessthree',
          name: 'PublicFundProcessthree',
          component: () => import('@/views/my/publicfund/PublicFundProcessthree.vue'),
          meta: {
            title: '输入转换密码',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'publicfundprocessfour',
          name: 'PublicFundProcessfour',
          component: () => import('@/views/my/publicfund/PublicFundProcessfour.vue'),
          meta: {
            title: '输入转换密码',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'buyingfund',
          name: 'BuyingFund',
          component: () => import('@/views/my/publicfund/BuyingFund.vue'),
          meta: {
            title: '买入基金',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'buyingfundpassword',
          name: 'BuyingFundPassword',
          component: () => import('@/views/my/publicfund/BuyingFundPassword.vue'),
          meta: {
            title: '快捷支付',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'buyingfundoffline',
          name: 'BuyingFundOffline',
          component: () => import('@/views/my/publicfund/BuyingFundOffline.vue'),
          meta: {
            title: '核对线下转账信息',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'buyingfundofflinefour',
          name: 'BuyingFundOfflinefour',
          component: () => import('@/views/my/publicfund/BuyingFundOfflinefour.vue'),
          meta: {
            title: '核对线下转账信息',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'buyingfundfour',
          name: 'BuyingFundfour',
          component: () => import('@/views/my/publicfund/BuyingFundfour.vue'),
          meta: {
            title: '线下转账交易结果',
            type: 2,
            name: 'PublicFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'privatefund',
          name: 'PrivateFund',
          component: () => import('@/views/my/privatefund/PrivateFund.vue'),
          meta: {
            title: '私募基金',
            type: 2,
            name: 'PrivateFund',
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'selloutfund',
          name: 'SellOutFund',
          component: () => import('@/views/my/privatefund/SellOutFund.vue'),
          meta: {
            title: '卖出基金',
            type: 2,
            name: 'PrivateFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'eelloutfundpassword',
          name: 'SellOutFundPassword',
          component: () => import('@/views/my/privatefund/SellOutFundPassword.vue'),
          meta: {
            title: '卖出基金输入密码',
            type: 2,
            name: 'PrivateFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        },
        {
          path: 'selloutfundfour',
          name: 'SellOutFundfour',
          component: () => import('@/views/my/privatefund/SellOutFundfour.vue'),
          meta: {
            title: '卖出基金交易结果',
            type: 2,
            name: 'PrivateFund',
            chdr: 1,
            titles: 'MyFundIndex'
          }
        }
      ]
    },
    {
      path: 'trade-record',
      name: 'TransactionRecord',
      component: () => import('@/views/my/transaction-record/TransactionRecord.vue'),
      meta: {
        title: '交易记录',
        type: 1,
        name: 'TransactionRecord'
      },
    },
    {
      path: 'trade-record/detail',
      name: 'TransactionRecordDetail',
      component: () => import('@/views/my/transaction-record/TransactionRecordDetail.vue'),
      meta: {
        title: '交易记录详情',
        name: 'TransactionRecord',
        chdr: 1,
        type: 1
      }
    },
    {
      path: 'trade-fundDetail',
      name: 'TradeFundDetail',
      component: () => import('@/views/my/transaction-record/TradeFundDetail.vue'),
      meta: {
        title: '交易记录详情',
        name: 'TransactionRecord',
        chdr: 1,
        type: 1
      }
    },
    {
      path: 'trad-fundokey',
      name: 'TradFundFour',
      component: () => import('@/views/my/transaction-record/TradFundFour.vue'),
      meta: {
        title: '订单确认结果',
        name: 'TransactionRecord',
        chdr: 1,
        type: 1
      }
    },
    {
      path: 'trad-detail',
      name: 'TradeDetail',
      component: () => import('@/views/my/transaction-record/TradeDetail.vue'),
      meta: {
        title: '交易记录详情',
        name: 'TransactionRecord',
        chdr: 1,
        type: 1
      }
    },
    // 账户管理
    {
      path: 'accountManage',
      component: Layout,
      meta: {
        title: '账户管理'
      },
      redirect: {
        name: "MyAccount"
      },
      children: [{
          path: 'myAccount',
          name: 'MyAccount',
          component: () => import('@/views/my/MyAccount'),
          meta: {
            title: '我的账户',
            type: 2,
            name: 'MyAccount',
            titles: 'MyAccount',
            acc: true
          }
        },
        {
          path: 'bankManage',
          name: 'BankCardManagement',
          component: () => import('@/views/my/bankManagement/BankCardManagement.vue'),
          meta: {
            title: '银行卡管理',
            type: 2,
            name: 'BankCardManagement',
            titles: 'MyAccount'
          }
        },
        {
          path: 'oldBanks',
          name: 'OldBankCard',
          component: () => import('@/views/my/bankManagement/OldBankCard.vue'),
          meta: {
            title: '银行卡管理',
            type: 2,
            name: 'OldBankCard',
            titles: 'MyAccount'
          }
        },
        {
          path: 'bankCardAdd',
          name: 'BankCardAdd',
          component: () => import('@/views/my/bankManagement/BankCardAdd.vue'),
          meta: {
            title: '新增银行卡',
            type: 2,
            name: 'BankCardManagement',
            chdr: 1,
            titles: 'MyAccount'
          }
        },
        {
          path: 'personalInfo',
          name: 'PersonalInfo',
          component: () => import('@/views/my/MyAccount/PersonalInfo.vue'),
          meta: {
            title: '个人信息',
            type: 2,
            name: 'PersonalInfo',
            titles: 'MyAccount',
            acc: true
          }
        },
        {
          path: 'safetyCentre',
          name: 'SafetyCentre',
          component: () => import('../views/my/bankManagement/SafetyCentre.vue'),
          meta: {
            title: '安全中心',
            type: 2,
            name: 'SafetyCentre',
            titles: 'MyAccount'
          }
        },
        {
          path: 'updateLoginPW',
          name: 'UpdateLoginPW',
          component: () => import('../views/my/bankManagement/updateLoginPW.vue'),
          meta: {
            title: '修改登录密码',
            type: 2,
            name: 'SafetyCentre',
            chdr: 1,
            titles: 'MyAccount'
          }
        },
        {
          path: 'resetLoginPW',
          name: 'ResetLoginPW',
          component: () => import('../views/my/bankManagement/resetLoginPW.vue'),
          meta: {
            title: '重置登录密码',
            type: 2,
            name: 'SafetyCentre',
            chdr: 1,
            titles: 'MyAccount'
          }
        },
        {
          path: 'updateTradePW',
          name: 'UpdateTradePW',
          component: () => import('../views/my/bankManagement/updateTradePW.vue'),
          meta: {
            title: '修改交易密码',
            type: 2,
            name: 'SafetyCentre',
            chdr: 1,
            titles: 'MyAccount'
          }
        },

        {
          path: 'resetTradePW',
          name: 'ResetTradePW',
          component: () => import('../views/my/bankManagement/resetTradePW.vue'),
          meta: {
            title: '重置交易密码',
            type: 2,
            name: 'SafetyCentre',
            chdr: 1,
            titles: 'MyAccount'
          }
        },
        {
          path: 'updateBindPhone',
          name: 'UpdateBindPhone',
          component: () => import('../views/my/bankManagement/updateBindPhone.vue'),
          meta: {
            title: '修改绑定手机',
            type: 2,
            name: 'SafetyCentre',
            chdr: 1,
            titles: 'MyAccount'
          }
        },
        {
          path: 'dividentFit',
          name: 'DividentFit',
          component: () => import('../views/my/AccountManage/DividentFit'),
          meta: {
            title: '分红设置',
            type: 2,
            name: 'DividentFit',
            titles: 'MyAccount'
          }
        },
        {
          path: 'riskAssessment',
          name: 'RiskAssessment',
          component: () => import('../views/my/bankManagement/RiskAssessment.vue'),
          meta: {
            title: '风险测评',
            type: 2,
            name: 'RiskAssessment',
            titles: 'MyAccount'
          }
        },
        {
          path: 'asset-prove',
          name: 'AssetProve',
          component: () => import('../views/my/AccountManage/AssetProve.vue'),
          meta: {
            title: '资产证明',
            type: 2,
            name: 'AssetProve',
            titles: 'MyAccount'
          }
        }
      ]
    },
    {
      path: 'dindetails',
      name: 'DinDetails',
      component: () => import('@/views/my/fundsetfing/DinDetails.vue'),
      meta: {
        title: '定投详情',
        type: 1,
        name: 'FundSetFing',
        chdr: 1
      },
    },
    {
      path: 'dinProcessmodify2',
      name: 'DinProcessmodify2',
      component: () => import('@/views/my/fundsetfing/DinProcessmodify2.vue'),
      meta: {
        title: '修改定投',
        type: 1,
        name: 'FundSetFing',
        chdr: 1
      },
    },
    {
      path: 'dinProcessmodify3',
      name: 'DinProcessmodify3',
      component: () => import('@/views/my/fundsetfing/DinProcessmodify3.vue'),
      meta: {
        title: '修改定投',
        type: 1,
        name: 'FundSetFing',
        chdr: 1
      },
    },
    {
      path: 'dinProcessmodify1',
      name: 'DinProcessmodify1',
      component: () => import('@/views/my/fundsetfing/DinProcessmodify1.vue'),
      meta: {
        title: '修改定投',
        type: 1,
        name: 'FundSetFing',
        chdr: 1
      },
    },
    {
      path: 'index',
      name: 'FundSetFing',
      component: () => import('@/views/my/fundsetfing/FundSetFing.vue'),
      meta: {
        title: '基金定投',
        type: 1,
        name: 'FundSetFing'
      }
    }
  ]
}
